import React, { useEffect, useState } from 'react'
import AOS from 'aos';
import 'aos/dist/aos.css';
import img1 from "../images/team1.jpeg"
import img2 from "../images/team2.jpeg"
import img3 from "../images/image123.jpg"
import img4 from "../images/image123.jpg"
import img5 from "../images/image123.jpg"
import img6 from "../images/image123.jpg"

export default function SmallTeam() {
    useEffect(() => {
        AOS.init({ duration: 800 });


    }, []);

    const [hoveredImage, setHoveredImage] = useState(null);
    const [defaultImage] = useState("img1");

    const handleMouseEnter = (imageName) => {
        setHoveredImage(imageName)
    }

    const handleMouseLeave = () => {
        setHoveredImage(null)
    }

    return (
        <div>
            <div data-aos="zoom-in" data-aos-easing="ease-out-cubic"
                data-aos-duration="3000" className='pt-16' >  <div className="flex justify-center items-center text-5xl md:text-6xl " > <span className="    " ><hr className=" w-14 md:w-20  border-2 border-yellow-600 " /></span>Team <span className="" ><hr className="  w-14 md:w-20 border-2  border-yellow-600 " /></span> </div> </div>
            <br />
            <br />
            <p data-aos="fade-in" data-aos-easing="ease-out-cubic"
                data-aos-duration="3000" className="lg:w-2/3 px-5 text-center mx-auto leading-relaxed text-base  ">
                Meet the driving force behind our dedicated and talented team, HashStackDevelopers. With our diverse skills and relentless passion, we work together to make the vision a reality. Together, we are committed to delivering outstanding results that take your digital journey to new heights.
            </p>
            <br />
            <br />
            <br />
            <div className="flex items-center justify-center h-screen">

                {/* <!-- Outer Big Circle --> */}
                <div data-aos="zoom-in" data-aos-easing="ease-out-cubic"
                    data-aos-duration="5000" className="w-[600px] h-[600px] rounded-full border-2 border-yellow-300  relative flex items-center justify-center">
                    {/* <!-- Inner Small Circle at Top-Left --> */}
                    <div onMouseEnter={
                        () => {
                            handleMouseEnter("img6")
                        }}
                        onMouseLeave={handleMouseLeave}
                        className="w-24 h-24  rounded-full absolute top-[40px] left-[40px]">
                        <img src={img6} alt="" className='  rounded-full h-24 w-24 object-cover border-2 border-yellow-300' />
                        {hoveredImage === "img6" && (

                            <div className='bg-yellow-600   p-2 w-40 mr-1  border-2 shadow-lg border-black z-10 absolute top-[20%] right-[100%] border-r-4  rounded-l-2xl' >
                                <p className='text-sm text-right font-semibold tracking-wider ' >Muzammil Mughal</p>
                                <p className='text-sm text-white text-right font-semibold tracking-wider' >IT Manager</p>
                            </div>
                        )}
                    </div>
                    <div onMouseEnter={
                        () => {
                            handleMouseEnter("img5")
                        }}
                        onMouseLeave={handleMouseLeave}
                        className="w-24 h-24  rounded-full absolute bottom-[40px] right-[40px]">
                        <img src={img5} alt="" className='rounded-full h-24 w-24 object-cover border-2 border-yellow-300' />
                        {hoveredImage === "img5" && (

                            <div className='bg-yellow-600 p-2 w-36 ml-1  border-2 shadow-lg border-black z-10 absolute top-[20%] left-[100%] border-l-4  rounded-r-2xl' >
                                <p className='text-sm font-semibold tracking-wider ' >Arooj Raja</p>
                                <p className='text-sm text-white font-semibold tracking-wider' >Project Manager</p>
                            </div>)


                        }</div>
                    <div onMouseEnter={
                        () => {
                            handleMouseEnter("img4")
                        }}
                        onMouseLeave={handleMouseLeave}
                        className="w-24 h-24  rounded-full absolute bottom-[40px] left-[40px]">
                        <img src={img4} alt="" className='rounded-full h-24 w-24 object-cover border-2 border-yellow-300' />
                        {hoveredImage === "img4" && (

                            <div className='bg-yellow-600 p-2 w-36 mr-1  border-2 shadow-lg border-black z-10 absolute top-[20%] right-[100%] border-r-4  rounded-l-2xl' >
                                <p className='text-sm text-right font-semibold tracking-wider ' >M. Faizan Imran</p>
                                <p className='text-sm text-white text-right font-semibold tracking-wider' >HR Manager</p>
                            </div>

                        )}
                    </div>

                    {/* <!-- Another Inner Big Circle in the Middle --> */}
                    <div data-aos="zoom-in" data-aos-easing="ease-out-cubic"
                        data-aos-duration="4000" className="w-[450px] h-[450px] rounded-full border-2 border-yellow-400 absolute flex items-center justify-center">
                        {/* <!-- Inner Small Circle at Top-Left of Second Circle --> */}
                        <div
                            onMouseEnter={
                                () => {
                                    handleMouseEnter("img3")
                                }}
                            onMouseLeave={handleMouseLeave}
                            className="w-24 h-24  rounded-full absolute top-20 -right-5"><img src={img3} alt="" className='rounded-full h-24 w-24 object-cover border-2 border-yellow-400' />
                            {hoveredImage === "img3" && (<div className='bg-yellow-600 p-2 w-48 ml-1  border-2 shadow-lg border-black z-10 absolute top-[20%] left-[100%] border-l-4  rounded-r-2xl' >
                                <p className='text-sm font-semibold tracking-wider ' >Talha Rizwan</p>
                                <p className='text-sm text-white font-semibold tracking-wider' >Chief Marketing Officer</p>
                            </div>)}
                        </div>
                        {/* <div className="w-24 h-24 bg-red-500 rounded-full absolute top-32 -left-10"></div> */}

                        {/* <!-- Yet Another Inner Big Circle in the Middle --> */}
                        <div data-aos="zoom-in" data-aos-easing="ease-out-cubic"
                            data-aos-duration="3000" className="w-[300px] h-[300px] rounded-full border-2 border-yellow-500 absolute flex items-center justify-center relative">
                            {/* <!-- Inner Small Circle at Top-Right of Third Circle --> */}
                            <div
                                onMouseEnter={
                                    () => {
                                        handleMouseEnter("img2")
                                    }}
                                onMouseLeave={handleMouseLeave}
                                className="w-24 h-24  rounded-full absolute flex flex-col items-center -bottom-[40px] left-30"><img src={img2} alt="" className='rounded-full h-24 w-24 object-cover border-2 border-yellow-500' />
                                {hoveredImage === "img2" && (<div className='bg-yellow-600 p-2 w-40 mt-1  border-2 shadow-lg border-black z-10 relative border-t-4  rounded-b-2xl' >
                                    <p className='text-sm font-semibold tracking-wider' >Syed Ahsan Naqvi</p>
                                    <p className='text-sm text-white font-semibold tracking-wider' >Engr. Manager</p>
                                </div>)}
                            </div>

                            {/* <!-- One More Inner Big Circle in the Middle --> */}
                            <div data-aos="zoom-in" data-aos-easing="ease-out-cubic"
                                data-aos-duration="2000" className="w-[150px] h-[150px] rounded-full border-2 border-yellow-600 absolute flex items-center justify-center relative">
                                {/* <!-- Inner Small Circle at Border of Fourth Circle --> */}
                                {/* <div className="w-24 h-24 bg-yellow-500 rounded-full absolute bottom-30 -right-10"></div> */}
                                <div
                                    // onMouseEnter={
                                    //     () => {
                                    //         handleMouseEnter("img1")
                                    //     }}
                                    // onMouseLeave={handleMouseLeave}
                                    className="w-24 h-24  rounded-full absolute -top-30 -left-10"><img src={img1} alt="" className='rounded-full h-24 w-24 object-cover border-2 border-yellow-600' />
                                    {defaultImage === "img1" && (<div className='bg-yellow-600 p-2 w-36 ml-1  border-2 shadow-lg border-black z-10 absolute top-[20%] left-[100%] border-l-4  rounded-r-2xl' >
                                        <p className='text-sm font-semibold tracking-wider ' >M. Mateen Imran</p>
                                        <p className='text-sm text-white font-semibold tracking-wider' >Founder CEO</p>
                                    </div>)}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}



// i want to add images in small circles

