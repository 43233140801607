
// import './App.css';
import Main from './Main';
// import Test from './components/Test';
// import Test2 from './components/test2';
// import 'https://fonts.googleapis.com/css2?family=Raleway&display=swap'




function App() {
  return (
    <>
      <div style={{ fontFamily: "Raleway, sans-serif" }} >

        <Main />
        {/* <Test /> */}
      </div>
    </>
  );
}

export default App;
