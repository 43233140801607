import React, { useEffect } from 'react'
import AOS from 'aos';
import 'aos/dist/aos.css';
import Particle from './Particle'
import img3 from "../images/image3.jpeg"
import SideNav from './SideNav'
import Btn from "./Btn"

import img1 from "../images/hash1.jpeg"
import img2 from "../images/hash2.jpeg"
import img12 from "../images/hash3.jpeg"
import img10 from "../images/hash4.jpeg"
import img9 from "../images/hash5.jpeg"
import img4 from "../images/hash6.jpeg"
import img13 from "../images/hash7.jpeg"
import img14 from "../images/hash8.jpeg"
import img15 from "../images/hash9.jpeg"
import img16 from "../images/hash10.jpeg"
import img17 from "../images/hash11.jpeg"
import img18 from "../images/hash12.jpeg"
import img5 from "../images/image2-removebg-preview.png"
import img7 from "../images/image3-removebg-preview.png"
import img8 from "../images/image4-removebg-preview.png"
import Footer from "../components/footer"
import SmallTeam from './SmallTeam';
import BigTeam from './BigTeam';

export default function About() {
    useEffect(() => {
        AOS.init({ duration: 800 });


    }, []);



    return (
        <div>
            <div className=''>
                <div className='relative z-20' >
                    <SideNav />
                </div>
                <div className='absolute' >
                    <div className='absolute z-10' >
                        <Btn />
                    </div>
                    <Particle />
                </div>


                <div className='bg-black h-[100vh] flex justify-center items-center '>
                    <h1 data-aos="fade-up" data-aos-easing="ease-out-cubic"
                        data-aos-duration="2000" className='text-center text-white  text-2xl font-bold '  > <span className='text-5xl text-yellow-600 font-bold'>ABOUT US </span> </h1>
                </div>

                <div className='block md:hidden' >
                    <br /><br /><br />
                </div>
                <div className='hidden md:block' >
                    <br /><br /><br />
                </div>
                <section className="  text-gray-600 body-font">

                    <div className=' ' >
                        <div className=" container mx-auto flex px-5 items-center justify-center flex-col">

                            <img data-aos="flip-left" data-aos-easing="ease-out-cubic"
                                data-aos-duration="3000" className="lg:w-2/6 md:w-3/6 w-5/6 object-cover object-center rounded" alt="hero" src={img3} />
                            <div className='block md:hidden' >
                                <br /><br />
                            </div>
                            <div className='hidden md:block' >
                                <br /><br />
                            </div>
                            <div className="text-center lg:w-2/3 w-full">
                                <h1 data-aos="flip-up" data-aos-easing="ease-out-cubic"
                                    data-aos-duration="3000" className="title-font sm:text-4xl text-3xl  font-medium text-yellow-600">Welcome to HashStack Developers!</h1>
                                <div className='block md:hidden' >
                                    <br />
                                </div>
                                <div className='hidden md:block' >
                                    <br />
                                </div>
                                <p data-aos="fade-up" data-aos-easing="ease-out-cubic"
                                    data-aos-duration="3000" className=" leading-relaxed">

                                    HashStack Developers where innovation meets technology seamlessly. We are not just a software company, We are the architects of digital solutions that are redefining industries. Our team is dedicated to turning ideas into reality by creating custom software that helps businesses face the challenges of tomorrow. With our ongoing commitment to excellence and a passion for pushing the boundaries, we are experts at turning content into cutting-edge applications. At HashStack Developers, we believe that code not only works flawlessly, but also enriches the user experience.
                                    Join us on a journey of creativity, technology and infinity. Your vision is our guide.

                                </p>

                            </div>
                        </div>
                    </div>
                </section>




                <div className='block md:hidden' >
                    <br /><br /><br /><br /><br /><br />
                </div>
                <div className='hidden md:block' >
                    <br /><br /><br /><br /><br /><br />
                </div>
                {/*  */}
                <div data-aos="zoom-in" data-aos-easing="ease-out-cubic"
                    data-aos-duration="3000" className='' >  <div className="flex justify-center items-center text-5xl md:text-6xl " > <span className="    " ><hr className=" w-14 md:w-20  border-2 border-yellow-600 " /></span> Gallery <span className="" ><hr className="  w-14 md:w-20 border-2  border-yellow-600 " /></span></div>
                </div>


                <div className='block md:hidden' >
                    <br /><br />
                </div>
                <div className='hidden md:block' >
                    <br /><br /><br />
                </div>

                <section className="    body-font">
                    <div className="container px-5  mx-auto">
                        <div className="flex flex-col text-center w-full mb-20">
                            <h1 data-aos="flip-up" data-aos-easing="ease-out-cubic"
                                data-aos-duration="3000" className="sm:text-3xl text-2xl font-medium title-font mb-4 text-yellow-600">Welcome to HashStack Developers Gallery</h1>
                            <p data-aos="fade-in" data-aos-easing="ease-out-cubic"
                                data-aos-duration="3000" className="lg:w-2/3 mx-auto leading-relaxed text-base">Visual response for HashStack developers. Our gallery is a pixel symphony that reveals the alchemy of creativity and technology. Get an in-depth picture of our software company where innovation happens.</p>
                        </div>
                        <div className=" flex flex-wrap -m-4">

                            <div className="lg:w-1/4 sm:w-1/2 p-4 ">
                                <div className="flex relative w-full md:h-[375px] h-[50vh] ">
                                    <img data-aos="flip-up" data-aos-easing="ease-out-cubic"
                                        data-aos-duration="3000" alt="gallery" className="relative inset-0 w-full h-full " src={img15} />

                                </div>
                            </div>
                            <div className="lg:w-1/4 sm:w-1/2 p-4 ">
                                <div className="flex relative w-full md:h-[375px] h-[50vh] ">
                                    <img data-aos="flip-up" data-aos-easing="ease-out-cubic"
                                        data-aos-duration="3000" alt="gallery" className="relative inset-0 w-full h-full " src={img16} />

                                </div>
                            </div>
                            <div className="lg:w-1/4 sm:w-1/2 p-4 ">
                                <div className="flex relative w-full md:h-[375px] h-[50vh] ">
                                    <img data-aos="flip-up" data-aos-easing="ease-out-cubic"
                                        data-aos-duration="3000" alt="gallery" className="relative inset-0 w-full h-full " src={img17} />

                                </div>
                            </div>
                            <div className="lg:w-1/4 sm:w-1/2 p-4 ">
                                <div className="flex relative w-full md:h-[375px] h-[50vh] ">
                                    <img data-aos="flip-up" data-aos-easing="ease-out-cubic"
                                        data-aos-duration="3000" alt="gallery" className="relative inset-0 w-full h-full " src={img18} />

                                </div>
                            </div>
                            <div className="lg:w-1/4 sm:w-1/2 p-4 ">
                                <div className="flex relative w-full md:h-[375px] h-[50vh] ">
                                    <img data-aos="flip-up" data-aos-easing="ease-out-cubic"
                                        data-aos-duration="3000" alt="gallery" className="relative inset-0 w-full h-full " src={img1} />

                                </div>
                            </div>
                            <div className="lg:w-1/4 sm:w-1/2   p-4">
                                <div className="flex relative w-full md:h-[375px] h-[50vh] ">
                                    <img data-aos="flip-up" data-aos-easing="ease-out-cubic"
                                        data-aos-duration="3000" alt="gallery" className="relative inset-0 w-full h-full " src={img2} />

                                </div>
                            </div>
                            <div className="lg:w-1/4 sm:w-1/2 p-4">
                                <div className="flex relative w-full md:h-[375px] h-[50vh] ">
                                    <img data-aos="flip-up" data-aos-easing="ease-out-cubic"
                                        data-aos-duration="3000" alt="gallery" className="relative inset-0 w-full h-full " src={img12} />
                                    {/* <div className="px-8 py-10 relative  w-full border-4 border-gray-200 bg-white opacity-0 hover:opacity-100">
                                        <h2 className="tracking-widest text-base title-font font-medium text-indigo-500 mb-1">THE QUAID DAY</h2>
                                       
                                        <p className="leading-relaxed">Quaid Day, also known as Quaid Azam Day, is a holy holiday to commemorate the extraordinary life and leadership of Pakistan's founding father, Muhammad Ali Jinnah.</p>
                                    </div> */}
                                </div>
                            </div>
                            {/* starts 1 */}
                            <div className="lg:w-1/4 sm:w-1/2 p-4">
                                <div className="flex relative w-full md:h-[375px] h-[50vh] ">
                                    <img data-aos="flip-up" data-aos-easing="ease-out-cubic"
                                        data-aos-duration="3000" alt="gallery" className="relative inset-0 w-full h-full " src={img4} />
                                    {/* <div className="px-8 py-10 relative  w-full border-4 border-gray-200 bg-white opacity-0 hover:opacity-100">
                                        <h2 className="tracking-widest text-base title-font font-medium text-indigo-500 mb-1">RAMADAN KAREEM</h2>
                                        
                                        <p className="leading-relaxed">Ramadan is a holy and holy month celebrated by Muslims all over the world. This is a time for spiritual reflection, self-discipline, and more commitment. During the month of Ramadan, Muslims fast from morning to evening without eating or drinking.</p>
                                    </div> */}
                                </div>
                            </div>
                            {/* ends 1 */}
                            <div className="lg:w-1/4 sm:w-1/2 p-4">
                                <div className="flex relative w-full md:h-[375px] h-[50vh] ">
                                    <img data-aos="flip-up" data-aos-easing="ease-out-cubic"
                                        data-aos-duration="3000" alt="gallery" className="relative inset-0 w-full h-full " src={img9} />
                                    {/* <div className="px-8 py-10 relative  w-full border-4 border-gray-200 bg-white opacity-0 hover:opacity-100">
                                        <h2 className="tracking-widest text-base title-font font-medium text-indigo-500 mb-1">Eid Milad Un Nabi</h2>
                                       
                                        <p className="leading-relaxed">Eid Milad un-Nabi, also known as Mawlid al-Nabi or the Prophet's Birthday, is a joyous occasion celebrated by Muslims to honor and commemorate the birth of the Prophet Muhammad (peace be upon him). It is a time of spiritual reflection, gratitude, and expressions of love for the Prophet.</p>
                                    </div> */}
                                </div>
                            </div>
                            <div className="lg:w-1/4 sm:w-1/2 p-4">
                                <div className="flex relative w-full md:h-[375px] h-[50vh] ">
                                    <img data-aos="flip-up" data-aos-easing="ease-out-cubic"
                                        data-aos-duration="3000" alt="gallery" className="relative inset-0 w-full h-full " src={img10} />
                                    {/* <div className="px-8 py-10 relative  w-full border-4 border-gray-200 bg-white opacity-0 hover:opacity-100">
                                        <h2 className="tracking-widest text-base title-font font-medium text-indigo-500 mb-1">THE ALLAMA IQBAL DAY</h2>
                                       
                                        <p className="leading-relaxed">Allama Iqbal Day commemorates the poet, philosopher and intellectual Dr. It is a day to honor and honor Allama Muhammad Iqbal.</p>
                                    </div> */}
                                </div>
                            </div>
                            <div className="lg:w-1/4 sm:w-1/2 p-4">
                                <div className="flex relative w-full md:h-[375px] h-[60vh] ">
                                    <img data-aos="flip-up" data-aos-easing="ease-out-cubic"
                                        data-aos-duration="3000" alt="gallery" className="relative inset-0 w-full h-full " src={img13} />
                                    {/* <div className="px-8 py-10 relative  w-full border-4 border-gray-200 bg-white opacity-0 hover:opacity-100">
                                        <h2 className="tracking-widest text-base title-font font-medium text-indigo-500 mb-1">HASHSTACK SERVICES</h2>
                                    
                                        <p className="leading-relaxed">HashStack Developers, where innovation merges seamlessly with technology. We're not just a software house; we're architects of digital solutions that redefine industries.</p>
                                    </div> */}
                                </div>
                            </div>
                            <div className="lg:w-1/4 sm:w-1/2 p-4">
                                <div className="flex relative w-full md:h-[375px] h-[60vh] ">
                                    <img data-aos="flip-up" data-aos-easing="ease-out-cubic"
                                        data-aos-duration="3000" alt="gallery" className="relative inset-0 w-[100vw] h-full " src={img14} />
                                    {/* <div className="px-8 py-10 relative  w-full border-4 border-gray-200 bg-white opacity-0 hover:opacity-100">
                                        <h2 className="tracking-widest text-base title-font font-medium text-indigo-500 mb-1">THE HASHSTACK</h2>
                                        <p className="leading-relaxed">HashStackDevelopers is a combination of innovation and performance. Our expert team produces digital solutions according to your needs. Join us to bring your ideas to life and stay one step ahead of technological developments.</p>
                                    </div> */}
                                </div>
                            </div>

                        </div>
                    </div>
                </section>


            </div>

            <div className='block md:hidden' >
                <br /><br /><br /><br />
            </div>
            <div className='hidden md:block' >
                <br /><br /><br /><br />
            </div>


            <div className='  flex justify-center pt-10 pb-10 pr-5 pl-5 md:pl-0 md:pr-0  items-center md:h-[100vh] ' >
                <div >
                    <div className='block md:hidden' >
                        <img data-aos="fade-up" data-aos-easing="ease-out-cubic"
                            data-aos-duration="2000" src={img8} alt="" />
                    </div>
                    <div className='hidden md:block '>
                        <div className='flex' >
                            <img data-aos="fade-right" data-aos-easing="ease-out-cubic"
                                data-aos-duration="2000" src={img7} alt="" />
                            <img data-aos="fade-up" data-aos-easing="ease-out-cubic"
                                data-aos-duration="2000" src={img5} alt="" />

                        </div>
                    </div>
                </div>
            </div >
            <div className='block md:hidden' >
                <br /><br />
            </div>
            <div className='hidden md:block' >
                <br /><br />
            </div>


            <div>
                <div className='hidden md:block' >
                    <BigTeam />
                </div>
                <div className='block md:hidden' >
                    <SmallTeam />
                </div>
            </div>
            <div className='block md:hidden' >
                <br /><br />
            </div>
            <div className='hidden md:block' >
                <br /><br /><br />
            </div>


            {/* Footer starts */}
            <div className='h-auto w-auto'>


                <Footer />



            </div>
            {/* Footer ends */}
        </div>

    )
}