import React, { useEffect } from 'react'
// import PhotoAlbum from "react-photo-album";
import { Link } from "react-router-dom";
import { GoDeviceMobile } from "react-icons/go";
import { HiShoppingBag } from "react-icons/hi";
import { FaPencilAlt } from "react-icons/fa";
import { GiMagnifyingGlass } from "react-icons/gi";
import { FaChartLine } from "react-icons/fa";
import { FaMeta } from "react-icons/fa6";
import { FaTiktok } from "react-icons/fa";
import { FaUsers } from "react-icons/fa";
import CountUp from 'react-countup'
import VisibilitySensor from 'react-visibility-sensor'
// import "../style/main.css"
import img5 from "../images/image2-removebg-preview.png"
import img7 from "../images/image3-removebg-preview.png"
import img8 from "../images/image4-removebg-preview.png"
import AOS from 'aos';
import 'aos/dist/aos.css';
import '../style/test.css'
import Btn from "./Btn"
import { BsCodeSlash } from "react-icons/bs";

import img6 from "../images/codevo1.jpeg"
import img9 from "../images/prog-logic2.jpeg"

import SideNav from './SideNav'
import Footer from "../components/footer"
import Particle from './Particle'

import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/styles.css';
import 'react-awesome-slider/dist/custom-animations/cube-animation.css';
import withAutoplay from 'react-awesome-slider/dist/autoplay';


import img2 from "../images/rev1.jpeg"
import img1 from "../images/rev2.jpeg"
import img3 from "../images/rev3.jpeg"
import img4 from "../images/rev4.jpeg"

import "../style/test.css"
import Flag from 'react-world-flags'

import BigSlider from './BigSlider'
import SmallSlider from './SmallSlider'
import BigTeam from './BigTeam'
import SmallTeam from './SmallTeam'



export default function All() {
    useEffect(() => {
        AOS.init({ duration: 800 });


    }, []);

    const AutoplaySlider = withAutoplay(AwesomeSlider);




    return (

        <div className=' overscroll-y-none overscroll-x-none ' >
            {/* <Add /> */}
            {/* <Modal /> */}

            {/* headerBar Starts */}
            <div className='h-8 w-full bg-yellow-600 absolute z-10 ' >

                <div className='flex justify-end ' >
                    <div className='w-full text-white flex justify-between ' >


                        <div data-aos="fade-down" data-aos-easing="ease-out-cubic"
                            data-aos-duration="1000" className=' hidden pt-[2px]   w-full md:flex justify-center' >
                            <span className='flex  ' ><Flag code="usa" width="30" height="5" /> &nbsp; <a target='blank' href="https://web.whatsapp.com/send?phone=15512271127" className='font-mono' >: +1 551 227 1127</a> </span>
                            {/* <span className='flex  ' ><Flag code="usa" width="30" height="5" /> &nbsp; <a href="https://web.whatsapp.com/send?phone=1(716) 218-3204">: +1(716) 218-3204</a> </span> */}
                            <span className='flex ml-12 ' ><Flag code="DE" width="30" height="5" /> &nbsp; <a target='blank' href="https://web.whatsapp.com/send?phone=491733819311" className='font-mono' > : +49 173 3819311</a> </span>
                            <span className='flex ml-12 ' ><Flag code="pak" width="30" height="5" /> &nbsp; <a target='blank' href="https://web.whatsapp.com/send?phone=92 3210006260" className='font-mono' >  : +92 321 0006260</a> </span>
                            <span className='flex ml-12 ' ><Flag code="CAN" width="30" height="5" /> &nbsp; <a target='blank' href="https://web.whatsapp.com/send?phone=16476484524" className='font-mono' >  : +1 647 648 4524</a> </span>
                            {/* <span className='flex ml-12 ' ><Flag code="pak" width="30" height="5" /> &nbsp; <a href="https://web.whatsapp.com/send?phone=92 3210006260">  : +92 3210006260 </a> </span> */}
                            {/* <span className='ml-8' >Germany: +49 162 9775400</span> */}
                        </div>


                        <div className="  flex md:hidden justify-between bg-yellow-600 h-10" >
                            {/* <marquee behavior="" direction=""> */}
                            <div data-aos="fade-down" data-aos-easing="ease-out-cubic"
                                data-aos-duration="1000" className='flex pt-[5px]' >
                                <span className='flex text-xs  ' ><Flag code="usa" width="17" height="5" /> &nbsp; <a target='blank' href="whatsapp://send?phone=15512271127" className='font-mono' >+1 551 2271127 </a> </span>
                                {/* <span className='flex text-xs  ' ><Flag code="usa" width="17" height="5" /> &nbsp; <a href="whatsapp://send?phone=1(716) 218-3204">: +1(716) 218-3204 </a> </span> */}
                                <span className='flex text-xs ml-2 ' ><Flag code="DE" width="17" height="5" /> &nbsp; <a target='blank' href="whatsapp://send?phone=491733819311" className='font-mono' >+49 173 3819311</a> </span>
                                <span className='flex text-xs ml-2 ' ><Flag code="pak" width="17" height="5" /> &nbsp; <a target='blank' href="whatsapp://send?phone=923210006260" className='font-mono' >+92 321 0006260 </a> </span>
                                <span className='flex text-xs ml-2 ' ><Flag code="CAN" width="17" height="5" /> &nbsp; <a target='blank' href="whatsapp://send?phone=16476484524" className='font-mono' >+1 647 648 4524</a> </span>
                                {/* <span className='flex text-xs ml-2 ' ><Flag code="pak" width="17" height="5" /> &nbsp; <a href="whatsapp://send?phone=923210006260">  : +92 3210006260 </a> </span> */}
                            </div>
                            {/* </marquee> */}
                        </div>
                    </div>
                </div>
            </div>


            {/* headerBar Ends */}

            <div className='relative z-20  ' >
                <SideNav />
            </div>
            <div className='absolute    ' >
                <div className='absolute z-10' >
                    <Btn />
                </div>
                <Particle />
            </div>



            <div className='h-[100vh] bg-black flex justify-center items-center ' >

                {/* <Particle /> */}
                <div className="   visible bg-black "  >
                    <span className='block md:hidden text-center text-yellow-600 flex justify-center' >
                        <span data-aos="fade-right" data-aos-easing="ease-out-cubic"
                            data-aos-duration="3000" className=' block md:hidden text-center text-yellow-600  text-4xl  font-bold pl-2 pr-2 md:pl-0 md:pr-0 '  >LET'S</span>
                        <span data-aos="fade-left" data-aos-easing="ease-out-cubic"
                            data-aos-duration="3000" className=' block md:hidden text-center text-yellow-600  text-4xl  font-bold pl-2 pr-2 md:pl-0 md:pr-0 '  >JOIN</span>
                    </span>
                    <span className=' block md:hidden text-center text-white  text-2xl font-bold mt-4 pl-2 pr-2 md:pl-0 md:pr-0 '  >  The  New Universe Of Technology</span>
                    <span className=' hidden md:block text-center text-white  text-2xl font-bold pl-2 pr-2 md:pl-0 md:pr-0 '  > <span className='  text-2xl md:text-5xl   text-yellow-600 font-bold'>LET'S JOIN</span> The  New Universe Of Technology</span>
                    <p data-aos="fade-in" data-aos-easing="ease-out-cubic"
                        data-aos-duration="3000" className='text-center text-white mt-4 text-lg md:text-xl'> We Develop Innovative <span className='text-yellow-600'> &</span> Creative Products <span className='text-yellow-600'> &</span> Services That Provide Total Communication <span className='text-yellow-600'> &</span> Information Solution</p>
                    <div className=' text-center mt-12 relative '>
                        <Link to={"/about"} >    <button data-aos="flip-up" data-aos-easing="ease-out-cubic"
                            data-aos-duration="3000" className="  relative focus:outline-none text-white  bg-yellow-600 hover:bg-yellow-600 focus:ring-4 focus:ring-yellow-300  rounded-lg text-lg md:text-xl px-5 py-2.5 mr-2 mb-2 dark:focus:ring-yellow-900 font-bold tracking-wider">Learn More</button></Link>
                        <Link to={"/contact"} >  <button data-aos="flip-up" data-aos-easing="ease-out-cubic"
                            data-aos-duration="3000" className=" relative text-black hover:text-stone-50 bg-white  focus:ring-4 focus:ring-blue-300  rounded-lg text-lg md:text-xl px-5 py-2.5 mr-2 mb-2   dark:hover:bg-black focus:outline-none dark:focus:ring-blue-800 font-bold tracking-wider">Contact Us</button> </Link  >
                        {/* <button className="  relative focus:outline-none text-white  bg-yellow-600 hover:bg-yellow-600 focus:ring-4 focus:ring-yellow-300  rounded-lg text-xl px-5 py-2.5 mr-2 mb-2 dark:focus:ring-yellow-900 font-bold">About Us</button> */}
                    </div>


                </div>
            </div>
            {/* Main  ends*/}



            {/* About starts */}
            <div className='block md:hidden' >
                <br /><br /><br />
            </div>
            <div className='hidden md:block' >
                <br /><br /><br />
            </div>
            <div data-aos="zoom-in" data-aos-easing="ease-out-cubic"
                data-aos-duration="3000" className='' >  <div className="flex justify-center items-center text-5xl md:text-6xl " > <span className="    " ><hr className=" w-14 md:w-20  border-2 border-yellow-600 " /></span> About <span className="" ><hr className="  w-14 md:w-20 border-2  border-yellow-600 " /></span> </div> </div>
            <div className=' overflow-x: hidden; flex justify-center items-center align-middle ' >
                <section className="text-black body-font ">
                    <div className="   container mx-auto flex px-5  md:flex-row flex-col items-center">

                        {/* For  Mobile */}
                        {/* For  Mobile */}
                        {/* For  Mobile */}
                        <div className='block md:hidden' >
                            <br /><br />
                        </div>
                        {/* For  Mobile */}
                        {/* For  Mobile */}
                        {/* For  Mobile */}

                        {/* For Laptop */}
                        {/* For Laptop */}
                        {/* For Laptop */}
                        <div className='hidden md:block' >
                            <br /><br /><br />
                            <br /><br /><br />
                            <br /><br /><br />
                            <br /><br /><br />
                            <br /><br /><br />
                            <br /><br /><br />
                            <br /><br /><br />
                        </div>
                        {/* For Laptop */}
                        {/* For Laptop */}
                        {/* For Laptop */}



                        <div data-aos="flip-left" data-aos-easing="ease-out-cubic"
                            data-aos-duration="3000" className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6  md:mb-0 flex items-center  justify-center  ">

                            <img className="object-cover object-center rounded" alt="hero" src={img7} />
                        </div>
                        <div className="lg:flex-grow md:w-1/2 lg:pl-24  md:pl-16 flex flex-col md:items-start md:text-left items-center ">
                            {/* For  Mobile */}
                            {/* For  Mobile */}
                            {/* For  Mobile */}
                            <div className='block md:hidden' >
                                <br /><br />
                            </div>
                            {/* For  Mobile */}
                            {/* For  Mobile */}
                            {/* For  Mobile */}



                            <div data-aos="flip-up" data-aos-easing="ease-out-cubic"
                                data-aos-duration="3000" >

                                <h1 className="title-font sm:text-4xl text-2xl mb-4 font-medium text-yellow-600  ">HASHSTACKDEVELOPERS
                                </h1>
                            </div>

                            <p data-aos="fade-in" data-aos-easing="ease-out-cubic"
                                data-aos-duration="3000" className=" leading-relaxed text-center md:text-left md:pl-0 md:pr-0 ">
                                Welcome to HashStackDevelopers, where innovation thrives. Our expert team produces digital solutions according to your needs. Join us to bring your ideas to life and stay one step ahead of technological developments.</p>
                            {/* <div className="flex justify-center"> */}
                            {/* <button className="inline-flex text-white bg-yellow-600 border-2 py-2 px-6  hover:bg-white hover:border-yellow-600 hover:text-black rounded text-lg">About Us</button> */}

                            <div className='block md:hidden' >
                                <br />
                            </div>
                            <div className='hidden md:block' >
                                <br />
                            </div>



                            <Link to={"/about"} >
                                <button data-aos="flip-up" data-aos-easing="ease-out-cubic"
                                    data-aos-duration="3000" className="py-2.5  relative focus:outline-none text-white  bg-yellow-600 hover:bg-yellow-600 focus:ring-4 focus:ring-yellow-300  rounded-lg text-xl px-5 mr-2 dark:focus:ring-yellow-900 font-bold">About Us</button>
                            </Link>
                            {/* <button className='bg-black text-white' >Hello</button> */}

                            {/* <button className="ml-4 inline-flex text-gray-700 bg-gray-100 border-0 py-2 px-6 focus:outline-none hover:bg-gray-200 rounded text-lg">Button</button> */}
                            {/* <button type="submit" className="inline-block px-6 py-2 border-2 border-yellow-600 text-white bg-yellow-600 font-medium text-xs leading-tight uppercase rounded hover:bg-black hover:bg-opacity-5 focus:outline-none focus:ring-0 transition duration-150 ease-in-out">
                                    Subscribe
                                </button> */}
                            {/* </div> */}
                        </div>
                    </div>
                </section>
            </div>
            {/* About ends */}




            {/* Services starts */}
            <div className='' >
                {/* For  Mobile */}
                {/* For  Mobile */}
                {/* For  Mobile */}
                <div className='block md:hidden' >
                    <br /><br /><br /><br /><br /><br />
                </div>
                {/* For  Mobile */}
                {/* For  Mobile */}
                {/* For  Mobile */}

                {/* For Laptop */}
                {/* For Laptop */}
                {/* For Laptop */}
                <div className='hidden md:block' >
                    <br /><br /><br />
                </div>
                {/* For Laptop */}
                {/* For Laptop */}
                {/* For Laptop */}
                {/* <div data-aos="zoom-in" data-aos-easing="ease-out-cubic"
                    data-aos-duration="3000" className='pt-16' ><h1 className='text-center font-bold text-6xl '>OUR SERVICES</h1>
                </div> */}
                {/*  */}
                <div data-aos="zoom-in" data-aos-easing="ease-out-cubic"
                    data-aos-duration="3000" className='' >  <div className=" flex justify-center items-center text-5xl md:text-6xl text-black" > <span className="    " ><hr className=" w-14 md:w-20  border-2 border-yellow-600 " /></span>Services<span className="" ><hr className="  w-14 md:w-20 border-2  border-yellow-600 " /></span></div>
                </div>

                {/*  */}
                <div className='block md:hidden' >
                    <br /><br />
                </div>
                <div className='hidden md:block' >
                    <br /><br />
                </div>

                <p data-aos="fade-in" data-aos-easing="ease-out-cubic"
                    data-aos-duration="3000" className="lg:w-2/3 px-5 text-center mx-auto leading-relaxed text-base  ">
                    The HashStack Developers community, where innovation knows no bounds. Let us be your digital number as you craft, train and take your company's technology to new heights.</p>

                <section className="text-black body-font">
                    <div className="container px-5 py-24 mx-auto">

                        <div className="flex flex-wrap -m-4">




                            {/* div1 starts */}
                            {/* onMouseEnter={() => { console.warn("Enter") }} onMouseLeave={() => { console.warn("Leave") }} */}

                            {/* <div className='w-[90vw] h-[55.4vh] bg-pink-500  lg:w-1/3 ' >
                                <div data-aos="fade-right" data-aos-once="false" data-aos-easing="ease-out-cubic"
                                    data-aos-duration="3000" className=" ">
                                    <div className=" hover:bg-black h-full overflow-x: hidden; bg-yellow-600 px-8 pt-16 pb-24 rounded-lg overflow-hidden text-center relative">
                                        <div className=' flex justify-center'> <span className='text-6xl text-black animate-spin '><BsCodeSlash /></span> </div>

                                     

                                        <h1 className="title-font sm:text-2xl text-xl font-medium  mb-3 text-black">WEBSITE DEVELOPMENT</h1>
                                        <p className="leading-relaxed mb-3 text-white">Photo booth fam kinfolk cold-pressed sriracha leggings jianbing microdosing tousled waistcoat.</p>
                                    </div>
                                </div>
                            </div> */}

                            {/* div1 ends */}

                            {/* div2 starts */}

                            <div data-aos="fade-right" data-aos-once="false" data-aos-easing="ease-out-cubic"
                                data-aos-duration="3000" className="p-4 lg:w-1/3  ">
                                <div className=" shadow-2xl service1 h-full overflow-x: hidden; bg-yellow-600 px-8 pt-16 pb-24 rounded-lg overflow-hidden text-center relative">
                                    {/* <div className=' flex justify-center'> <span className='text-6xl text-black '><BsCodeSlash /> </span> </div> */}
                                    {/* <div className=' flex justify-center'> <span className='text-6xl text-black '><UseAnimations animation={activity} size={56} /></span> </div> */}
                                    <div className=' flex justify-center'> <span className=' service1_icon text-6xl text-black animate-spin '><BsCodeSlash /></span> </div>



                                    <h1 className="title-font sm:text-2xl text-xl font-medium  mb-3 text-black">WEBSITE DEVELOPMENT</h1>

                                    <p className="leading-relaxed mb-3 text-white">Enhance your online presence by engaging your audience with responsive and user-centric web applications.
                                    </p>
                                </div>
                            </div>

                            {/* div2 ends */}

                            <div data-aos="fade-up" data-aos-easing="ease-out-cubic"
                                data-aos-duration="3000" className="p-4 lg:w-1/3">
                                <div className="shadow-2xl service2 h-full overflow-x: hidden;  bg-yellow-600  px-8 pt-16 pb-24 rounded-lg overflow-hidden text-center relative">
                                    <div className=' flex justify-center'> <span className='text-6xl service2_icon text-black animate-spin  '>< GoDeviceMobile /> </span> </div>


                                    <h1 className="title-font sm:text-2xl text-xl font-medium text-black mb-3">APPLICATION DEVELOPMENT</h1>
                                    <p className="leading-relaxed mb-3 text-white ">Harness the power of technology with our best app development for iOS, Android and cross-platform solutions.</p>

                                </div>
                            </div>
                            <div data-aos="fade-left" data-aos-easing="ease-out-cubic"
                                data-aos-duration="3000" className="p-4 lg:w-1/3">
                                <div className="shadow-2xl service3 h-full overflow-x: hidden;  bg-yellow-600   px-8 pt-16 pb-24 rounded-lg overflow-hidden text-center relative">
                                    <div className=' flex justify-center'> <span className='text-6xl service3_icon text-black animate-spin '>< HiShoppingBag /> </span> </div>


                                    <h1 className="title-font sm:text-2xl text-xl font-medium text-black mb-3 ">WORDPRESS & SHOPIFY</h1>
                                    <p className="leading-relaxed mb-3 text-white">Promote your digital presence with HashStackDevelopers. We specialize in creating WordPress websites and integrating them with Spotify to create a great experience that engages your audience. Reach new heights in innovation with us.</p>


                                </div>
                            </div>
                        </div>
                        <div className="flex flex-wrap mt-4 -m-4">
                            <div className="p-4 lg:w-1/3">
                                <div data-aos="fade-right" data-aos-easing="ease-out-cubic"
                                    data-aos-duration="3000" className="shadow-2xl service4 h-full overflow-x: hidden; bg-yellow-600 px-8 pt-16 pb-24 rounded-lg overflow-hidden text-center relative">
                                    <div className=' flex justify-center'> <span className='text-6xl text-black  service4_icon animate-spin '><FaChartLine /> </span> </div>


                                    <h1 className="title-font sm:text-2xl text-xl font-medium  mb-3  text-black ">DIGITAL MARKETING</h1>
                                    <p className="leading-relaxed mb-3 text-white ">Promote your brand with digital marketing from HashStackDevelopers. We are with you in everything from SEO to social media. Let's conquer the digital landscape.</p>
                                </div>
                            </div>
                            <div data-aos="fade-up" data-aos-easing="ease-out-cubic"
                                data-aos-duration="3000" className="p-4 lg:w-1/3">
                                <div className="h-full shadow-2xl service4  overflow-x: hidden; bg-yellow-600 px-8 pt-16 pb-24 rounded-lg overflow-hidden text-center relative">
                                    <div className=' flex justify-center'> <span className='text-6xl text-black service4_icon animate-spin '><GiMagnifyingGlass /> </span> </div>


                                    <h1 className="title-font sm:text-2xl text-xl font-medium  text-black mb-3">SEO</h1>
                                    <p className="leading-relaxed mb-3 text-white">Enhance your online presence with the SEO magic of HashStackDevelopers. Increase your search rankings, connect with your audience and succeed in the digital world.</p>

                                </div>
                            </div>
                            <div data-aos="fade-left" data-aos-easing="ease-out-cubic"
                                data-aos-duration="3000" className="p-4 lg:w-1/3">
                                <div className="h-full shadow-2xl service5 overflow-x: hidden; bg-yellow-600 px-8 pt-16 pb-24 rounded-lg overflow-hidden text-center relative">
                                    <div className=' flex justify-center'> <span className='text-6xl text-black service5_icon animate-spin '>< FaPencilAlt /> </span> </div>


                                    <h1 className="title-font sm:text-2xl text-xl font-medium  text-black mb-3 ">GRAPHIC DESIGNING</h1>
                                    <p className="leading-relaxed mb-3 text-white">Have fun building with HashStackDevelopers. We turn ideas into inspiring and engaging insights that make a lasting impact for your brand.</p>


                                </div>
                            </div>
                        </div>
                        <div className="flex flex-wrap mt-4 -m-4">
                            <div className="p-4 lg:w-1/3">
                                <div data-aos="fade-right" data-aos-easing="ease-out-cubic"
                                    data-aos-duration="3000" className="shadow-2xl service4 h-full overflow-x: hidden; bg-yellow-600 px-8 pt-16 pb-24 rounded-lg overflow-hidden text-center relative">
                                    <div className=' flex justify-center'> <span className='text-6xl text-black  service4_icon animate-spin '><FaMeta /> </span> </div>


                                    <h1 className="title-font sm:text-2xl text-xl font-medium  mb-3  text-black ">META ADVERTISEMENT</h1>
                                    <p className="leading-relaxed mb-3 text-white ">Join the meta-minds at HashStack and redefine the future of development. Unlock limitless potential with our innovative solutions that transcend the ordinary.</p>
                                </div>
                            </div>
                            <div data-aos="fade-up" data-aos-easing="ease-out-cubic"
                                data-aos-duration="3000" className="p-4 lg:w-1/3">
                                <div className="h-full shadow-2xl service4  overflow-x: hidden; bg-yellow-600 px-8 pt-16 pb-24 rounded-lg overflow-hidden text-center relative">
                                    <div className=' flex justify-center'> <span className='text-6xl text-black service4_icon animate-spin '> <FaTiktok /></span> </div>


                                    <h1 className="title-font sm:text-2xl text-xl font-medium  text-black mb-3">TIKTOK ADVERTISEMENT</h1>
                                    <p className="leading-relaxed mb-3 text-white">Level up your coding journey with HashStack devs! Dive into the world of innovation and unlock your full potential.</p>

                                </div>
                            </div>
                            <div data-aos="fade-left" data-aos-easing="ease-out-cubic"
                                data-aos-duration="3000" className="p-4 lg:w-1/3">
                                <div className="h-full shadow-2xl service5 overflow-x: hidden; bg-yellow-600 px-8 pt-16 pb-24 rounded-lg overflow-hidden text-center relative">
                                    <div className=' flex justify-center'> <span className='text-6xl text-black service5_icon animate-spin '> <FaUsers /> </span> </div>


                                    <h1 className="title-font sm:text-2xl text-xl font-medium  text-black mb-3 ">SOCIAL MEDIA MANAGEMENT</h1>
                                    <p className="leading-relaxed mb-3  text-white">Empower your digital presence with HashStack's expert social media management. Let us navigate the complexities while you focus on what you do best - coding brilliance.</p>


                                </div>
                            </div>
                        </div>
                    </div>
                </section>




            </div>
            {/* Services ends */}





            < br />

            < br />


            {/* Testimonials starts */}

            {/* Testimonials ends */}


            <div className='block md:hidden' >
                <br /><br />
            </div>
            <div className='hidden md:block' >
                <br /><br />
            </div>

            {/* slider starts */}


            <div data-aos="zoom-in" data-aos-easing="ease-out-cubic"
                data-aos-duration="3000" className='' >  <div className="flex justify-center items-center text-5xl md:text-6xl text-black " > <span className="    " ><hr className=" w-14 md:w-20  border-2 border-yellow-600 " /></span>SDLC <span className="" ><hr className="  w-14 md:w-20 border-2  border-yellow-600 " /></span> </div> </div>

            <div className='block md:hidden' >
                <br /><br />
            </div>
            <div className='hidden md:block' >
                <br /><br />
            </div>
            {/*  */}


            <p data-aos="fade-in" data-aos-easing="ease-out-cubic"
                data-aos-duration="3000" className="lg:w-2/3 px-5 text-center mx-auto leading-relaxed text-base  ">
                Our software development process ensures efficient planning, precise coding, rigorous testing, and seamless deployment, delivering high-quality solutions for success.</p>


            <div className='block md:hidden' >
                <br /><br />
            </div>
            <div className='hidden md:block' >
                <br /><br />
            </div>

            <div>
                <div className='hidden md:block' >
                    <BigSlider />
                </div>
                <div className='block md:hidden' >
                    <SmallSlider />
                </div>
            </div>



            <div className='block md:hidden' >
                <br /><br /><br />
            </div>
            <div className='hidden md:block' >
                <br /><br /><br />
            </div>




            <div>
                <div className='hidden md:block' >
                    <BigTeam />
                </div>
                <div className='block md:hidden' >
                    <SmallTeam />
                </div>
            </div>


            <div className='block md:hidden' >
                <br /><br />
            </div>
            <div className='hidden md:block' >
                <br /><br /><br /><br /><br />
            </div>


            {/* slider starts */}

            <div data-aos="zoom-in" data-aos-easing="ease-out-cubic"
                data-aos-duration="3000" className='' >  <div className="flex justify-center items-center text-5xl md:text-6xl text-black " > <span className="    " ><hr className=" w-14 md:w-20  border-2 border-yellow-600 " /></span > Reviews <span className="" ><hr className="  w-14 md:w-20 border-2  border-yellow-600 " /></span></div>
            </div>
            {/*  */}

            <div className='block md:hidden' >
                <br /><br />
            </div>
            <div className='hidden md:block' >
                <br /><br />
            </div>




            <div className='bg-white h-[60vh] flex ' data-aos="zoom-in" data-aos-easing="ease-out-cubic"
                data-aos-duration="3000" >




                <AutoplaySlider
                    play={true}
                    cancelOnInteraction={false} // should stop playing on user interaction
                    interval={5000}
                    bullets={false}
                    fillParent={false}
                    buttons={false}
                >

                    <div style={{ background: "none" }}  >
                        <div style={{ background: "none" }} >  <section className="  text-black body-font">
                            <div className="container px-5 py-10 mx-auto w-auto md:w-[50%] lg:w-[60%] ">
                                {/* <div className="xl:w-1/2 lg:w-3/4 w-full mx-auto text-center"> */}
                                <div className="xl:w-auto lg:w-auto w-full mx-auto text-center">
                                    {/* rating starts */}
                                    <div className='' >
                                        <div className="bg-yellow-600 rounded-lg p-10  shadow-xl  overflow-hidden mx-auto"  >
                                            {/* <!-- Round Image --> */}
                                            <div className="flex justify-center items-center mt-4">
                                                {/* <!-- Placeholder image from Lorem Picsum --> */}
                                                <img src={img1} alt="" className="rounded-full h-20 w-20" />
                                            </div>
                                            {/* <!-- Stars, Name, and Message --> */}
                                            <div className="text-center px-0 py-0 md:px-6 md:py-4">
                                                {/* <!-- Stars (Adjust the value of 'rating' accordingly) --> */}
                                                <div className="flex justify-center mb-2">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 128 128"><path fill="#fdd835" d="m68.05 7.23l13.46 30.7a7.047 7.047 0 0 0 5.82 4.19l32.79 2.94c3.71.54 5.19 5.09 2.5 7.71l-24.7 20.75c-2 1.68-2.91 4.32-2.36 6.87l7.18 33.61c.63 3.69-3.24 6.51-6.56 4.76L67.56 102a7.033 7.033 0 0 0-7.12 0l-28.62 16.75c-3.31 1.74-7.19-1.07-6.56-4.76l7.18-33.61c.54-2.55-.36-5.19-2.36-6.87L5.37 52.78c-2.68-2.61-1.2-7.17 2.5-7.71l32.79-2.94a7.047 7.047 0 0 0 5.82-4.19l13.46-30.7c1.67-3.36 6.45-3.36 8.11-.01" /><path fill="#ffff8d" d="m67.07 39.77l-2.28-22.62c-.09-1.26-.35-3.42 1.67-3.42c1.6 0 2.47 3.33 2.47 3.33l6.84 18.16c2.58 6.91 1.52 9.28-.97 10.68c-2.86 1.6-7.08.35-7.73-6.13" /><path fill="#f4b400" d="M95.28 71.51L114.9 56.2c.97-.81 2.72-2.1 1.32-3.57c-1.11-1.16-4.11.51-4.11.51l-17.17 6.71c-5.12 1.77-8.52 4.39-8.82 7.69c-.39 4.4 3.56 7.79 9.16 3.97" /></svg>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 128 128"><path fill="#fdd835" d="m68.05 7.23l13.46 30.7a7.047 7.047 0 0 0 5.82 4.19l32.79 2.94c3.71.54 5.19 5.09 2.5 7.71l-24.7 20.75c-2 1.68-2.91 4.32-2.36 6.87l7.18 33.61c.63 3.69-3.24 6.51-6.56 4.76L67.56 102a7.033 7.033 0 0 0-7.12 0l-28.62 16.75c-3.31 1.74-7.19-1.07-6.56-4.76l7.18-33.61c.54-2.55-.36-5.19-2.36-6.87L5.37 52.78c-2.68-2.61-1.2-7.17 2.5-7.71l32.79-2.94a7.047 7.047 0 0 0 5.82-4.19l13.46-30.7c1.67-3.36 6.45-3.36 8.11-.01" /><path fill="#ffff8d" d="m67.07 39.77l-2.28-22.62c-.09-1.26-.35-3.42 1.67-3.42c1.6 0 2.47 3.33 2.47 3.33l6.84 18.16c2.58 6.91 1.52 9.28-.97 10.68c-2.86 1.6-7.08.35-7.73-6.13" /><path fill="#f4b400" d="M95.28 71.51L114.9 56.2c.97-.81 2.72-2.1 1.32-3.57c-1.11-1.16-4.11.51-4.11.51l-17.17 6.71c-5.12 1.77-8.52 4.39-8.82 7.69c-.39 4.4 3.56 7.79 9.16 3.97" /></svg>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 128 128"><path fill="#fdd835" d="m68.05 7.23l13.46 30.7a7.047 7.047 0 0 0 5.82 4.19l32.79 2.94c3.71.54 5.19 5.09 2.5 7.71l-24.7 20.75c-2 1.68-2.91 4.32-2.36 6.87l7.18 33.61c.63 3.69-3.24 6.51-6.56 4.76L67.56 102a7.033 7.033 0 0 0-7.12 0l-28.62 16.75c-3.31 1.74-7.19-1.07-6.56-4.76l7.18-33.61c.54-2.55-.36-5.19-2.36-6.87L5.37 52.78c-2.68-2.61-1.2-7.17 2.5-7.71l32.79-2.94a7.047 7.047 0 0 0 5.82-4.19l13.46-30.7c1.67-3.36 6.45-3.36 8.11-.01" /><path fill="#ffff8d" d="m67.07 39.77l-2.28-22.62c-.09-1.26-.35-3.42 1.67-3.42c1.6 0 2.47 3.33 2.47 3.33l6.84 18.16c2.58 6.91 1.52 9.28-.97 10.68c-2.86 1.6-7.08.35-7.73-6.13" /><path fill="#f4b400" d="M95.28 71.51L114.9 56.2c.97-.81 2.72-2.1 1.32-3.57c-1.11-1.16-4.11.51-4.11.51l-17.17 6.71c-5.12 1.77-8.52 4.39-8.82 7.69c-.39 4.4 3.56 7.79 9.16 3.97" /></svg>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 128 128"><path fill="#fdd835" d="m68.05 7.23l13.46 30.7a7.047 7.047 0 0 0 5.82 4.19l32.79 2.94c3.71.54 5.19 5.09 2.5 7.71l-24.7 20.75c-2 1.68-2.91 4.32-2.36 6.87l7.18 33.61c.63 3.69-3.24 6.51-6.56 4.76L67.56 102a7.033 7.033 0 0 0-7.12 0l-28.62 16.75c-3.31 1.74-7.19-1.07-6.56-4.76l7.18-33.61c.54-2.55-.36-5.19-2.36-6.87L5.37 52.78c-2.68-2.61-1.2-7.17 2.5-7.71l32.79-2.94a7.047 7.047 0 0 0 5.82-4.19l13.46-30.7c1.67-3.36 6.45-3.36 8.11-.01" /><path fill="#ffff8d" d="m67.07 39.77l-2.28-22.62c-.09-1.26-.35-3.42 1.67-3.42c1.6 0 2.47 3.33 2.47 3.33l6.84 18.16c2.58 6.91 1.52 9.28-.97 10.68c-2.86 1.6-7.08.35-7.73-6.13" /><path fill="#f4b400" d="M95.28 71.51L114.9 56.2c.97-.81 2.72-2.1 1.32-3.57c-1.11-1.16-4.11.51-4.11.51l-17.17 6.71c-5.12 1.77-8.52 4.39-8.82 7.69c-.39 4.4 3.56 7.79 9.16 3.97" /></svg>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 128 128"><path fill="#fdd835" d="m68.05 7.23l13.46 30.7a7.047 7.047 0 0 0 5.82 4.19l32.79 2.94c3.71.54 5.19 5.09 2.5 7.71l-24.7 20.75c-2 1.68-2.91 4.32-2.36 6.87l7.18 33.61c.63 3.69-3.24 6.51-6.56 4.76L67.56 102a7.033 7.033 0 0 0-7.12 0l-28.62 16.75c-3.31 1.74-7.19-1.07-6.56-4.76l7.18-33.61c.54-2.55-.36-5.19-2.36-6.87L5.37 52.78c-2.68-2.61-1.2-7.17 2.5-7.71l32.79-2.94a7.047 7.047 0 0 0 5.82-4.19l13.46-30.7c1.67-3.36 6.45-3.36 8.11-.01" /><path fill="#ffff8d" d="m67.07 39.77l-2.28-22.62c-.09-1.26-.35-3.42 1.67-3.42c1.6 0 2.47 3.33 2.47 3.33l6.84 18.16c2.58 6.91 1.52 9.28-.97 10.68c-2.86 1.6-7.08.35-7.73-6.13" /><path fill="#f4b400" d="M95.28 71.51L114.9 56.2c.97-.81 2.72-2.1 1.32-3.57c-1.11-1.16-4.11.51-4.11.51l-17.17 6.71c-5.12 1.77-8.52 4.39-8.82 7.69c-.39 4.4 3.56 7.79 9.16 3.97" /></svg>
                                                </div>

                                                {/* <!-- Name --> */}
                                                <h2 className="font-bold text-lg uppercase text-black">Lisa Lurie</h2>

                                                {/* <!-- Dummy Message --> */}
                                                <p className="text-white mt-2">HashStackDevelopers delivered beyond expectations! Their team's expertise and dedication transformed our project into a seamless masterpiece. Exceptional communication, Highly recommend!</p>
                                            </div>
                                        </div>


                                    </div>

                                    {/* rating ends */}
                                </div>
                            </div>
                        </section></div>
                    </div>
                    <div style={{ background: "none" }}  >
                        <div style={{ background: "none" }} >  <section className="  text-black body-font">
                            <div className="container px-5 py-10 mx-auto w-auto md:w-[50%] lg:w-[60%] ">
                                {/* <div className="xl:w-1/2 lg:w-3/4 w-full mx-auto text-center"> */}
                                <div className="xl:w-auto lg:w-auto w-full mx-auto text-center">
                                    {/* rating starts */}
                                    <div className='' >
                                        <div className="bg-yellow-600 rounded-lg p-10  shadow-xl  overflow-hidden mx-auto"  >
                                            {/* <!-- Round Image --> */}
                                            <div className="flex justify-center items-center mt-4">
                                                {/* <!-- Placeholder image from Lorem Picsum --> */}
                                                <img src={img2} alt="" className="rounded-full h-20 w-20" />
                                            </div>

                                            {/* <!-- Stars, Name, and Message --> */}
                                            <div className="text-center px-0 py-0 md:px-6 md:py-4">
                                                {/* <!-- Stars (Adjust the value of 'rating' accordingly) --> */}
                                                <div className="flex justify-center mb-2">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 128 128"><path fill="#fdd835" d="m68.05 7.23l13.46 30.7a7.047 7.047 0 0 0 5.82 4.19l32.79 2.94c3.71.54 5.19 5.09 2.5 7.71l-24.7 20.75c-2 1.68-2.91 4.32-2.36 6.87l7.18 33.61c.63 3.69-3.24 6.51-6.56 4.76L67.56 102a7.033 7.033 0 0 0-7.12 0l-28.62 16.75c-3.31 1.74-7.19-1.07-6.56-4.76l7.18-33.61c.54-2.55-.36-5.19-2.36-6.87L5.37 52.78c-2.68-2.61-1.2-7.17 2.5-7.71l32.79-2.94a7.047 7.047 0 0 0 5.82-4.19l13.46-30.7c1.67-3.36 6.45-3.36 8.11-.01" /><path fill="#ffff8d" d="m67.07 39.77l-2.28-22.62c-.09-1.26-.35-3.42 1.67-3.42c1.6 0 2.47 3.33 2.47 3.33l6.84 18.16c2.58 6.91 1.52 9.28-.97 10.68c-2.86 1.6-7.08.35-7.73-6.13" /><path fill="#f4b400" d="M95.28 71.51L114.9 56.2c.97-.81 2.72-2.1 1.32-3.57c-1.11-1.16-4.11.51-4.11.51l-17.17 6.71c-5.12 1.77-8.52 4.39-8.82 7.69c-.39 4.4 3.56 7.79 9.16 3.97" /></svg>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 128 128"><path fill="#fdd835" d="m68.05 7.23l13.46 30.7a7.047 7.047 0 0 0 5.82 4.19l32.79 2.94c3.71.54 5.19 5.09 2.5 7.71l-24.7 20.75c-2 1.68-2.91 4.32-2.36 6.87l7.18 33.61c.63 3.69-3.24 6.51-6.56 4.76L67.56 102a7.033 7.033 0 0 0-7.12 0l-28.62 16.75c-3.31 1.74-7.19-1.07-6.56-4.76l7.18-33.61c.54-2.55-.36-5.19-2.36-6.87L5.37 52.78c-2.68-2.61-1.2-7.17 2.5-7.71l32.79-2.94a7.047 7.047 0 0 0 5.82-4.19l13.46-30.7c1.67-3.36 6.45-3.36 8.11-.01" /><path fill="#ffff8d" d="m67.07 39.77l-2.28-22.62c-.09-1.26-.35-3.42 1.67-3.42c1.6 0 2.47 3.33 2.47 3.33l6.84 18.16c2.58 6.91 1.52 9.28-.97 10.68c-2.86 1.6-7.08.35-7.73-6.13" /><path fill="#f4b400" d="M95.28 71.51L114.9 56.2c.97-.81 2.72-2.1 1.32-3.57c-1.11-1.16-4.11.51-4.11.51l-17.17 6.71c-5.12 1.77-8.52 4.39-8.82 7.69c-.39 4.4 3.56 7.79 9.16 3.97" /></svg>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 128 128"><path fill="#fdd835" d="m68.05 7.23l13.46 30.7a7.047 7.047 0 0 0 5.82 4.19l32.79 2.94c3.71.54 5.19 5.09 2.5 7.71l-24.7 20.75c-2 1.68-2.91 4.32-2.36 6.87l7.18 33.61c.63 3.69-3.24 6.51-6.56 4.76L67.56 102a7.033 7.033 0 0 0-7.12 0l-28.62 16.75c-3.31 1.74-7.19-1.07-6.56-4.76l7.18-33.61c.54-2.55-.36-5.19-2.36-6.87L5.37 52.78c-2.68-2.61-1.2-7.17 2.5-7.71l32.79-2.94a7.047 7.047 0 0 0 5.82-4.19l13.46-30.7c1.67-3.36 6.45-3.36 8.11-.01" /><path fill="#ffff8d" d="m67.07 39.77l-2.28-22.62c-.09-1.26-.35-3.42 1.67-3.42c1.6 0 2.47 3.33 2.47 3.33l6.84 18.16c2.58 6.91 1.52 9.28-.97 10.68c-2.86 1.6-7.08.35-7.73-6.13" /><path fill="#f4b400" d="M95.28 71.51L114.9 56.2c.97-.81 2.72-2.1 1.32-3.57c-1.11-1.16-4.11.51-4.11.51l-17.17 6.71c-5.12 1.77-8.52 4.39-8.82 7.69c-.39 4.4 3.56 7.79 9.16 3.97" /></svg>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 128 128"><path fill="#fdd835" d="m68.05 7.23l13.46 30.7a7.047 7.047 0 0 0 5.82 4.19l32.79 2.94c3.71.54 5.19 5.09 2.5 7.71l-24.7 20.75c-2 1.68-2.91 4.32-2.36 6.87l7.18 33.61c.63 3.69-3.24 6.51-6.56 4.76L67.56 102a7.033 7.033 0 0 0-7.12 0l-28.62 16.75c-3.31 1.74-7.19-1.07-6.56-4.76l7.18-33.61c.54-2.55-.36-5.19-2.36-6.87L5.37 52.78c-2.68-2.61-1.2-7.17 2.5-7.71l32.79-2.94a7.047 7.047 0 0 0 5.82-4.19l13.46-30.7c1.67-3.36 6.45-3.36 8.11-.01" /><path fill="#ffff8d" d="m67.07 39.77l-2.28-22.62c-.09-1.26-.35-3.42 1.67-3.42c1.6 0 2.47 3.33 2.47 3.33l6.84 18.16c2.58 6.91 1.52 9.28-.97 10.68c-2.86 1.6-7.08.35-7.73-6.13" /><path fill="#f4b400" d="M95.28 71.51L114.9 56.2c.97-.81 2.72-2.1 1.32-3.57c-1.11-1.16-4.11.51-4.11.51l-17.17 6.71c-5.12 1.77-8.52 4.39-8.82 7.69c-.39 4.4 3.56 7.79 9.16 3.97" /></svg>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 128 128"><path fill="#fdd835" d="m68.05 7.23l13.46 30.7a7.047 7.047 0 0 0 5.82 4.19l32.79 2.94c3.71.54 5.19 5.09 2.5 7.71l-24.7 20.75c-2 1.68-2.91 4.32-2.36 6.87l7.18 33.61c.63 3.69-3.24 6.51-6.56 4.76L67.56 102a7.033 7.033 0 0 0-7.12 0l-28.62 16.75c-3.31 1.74-7.19-1.07-6.56-4.76l7.18-33.61c.54-2.55-.36-5.19-2.36-6.87L5.37 52.78c-2.68-2.61-1.2-7.17 2.5-7.71l32.79-2.94a7.047 7.047 0 0 0 5.82-4.19l13.46-30.7c1.67-3.36 6.45-3.36 8.11-.01" /><path fill="#ffff8d" d="m67.07 39.77l-2.28-22.62c-.09-1.26-.35-3.42 1.67-3.42c1.6 0 2.47 3.33 2.47 3.33l6.84 18.16c2.58 6.91 1.52 9.28-.97 10.68c-2.86 1.6-7.08.35-7.73-6.13" /><path fill="#f4b400" d="M95.28 71.51L114.9 56.2c.97-.81 2.72-2.1 1.32-3.57c-1.11-1.16-4.11.51-4.11.51l-17.17 6.71c-5.12 1.77-8.52 4.39-8.82 7.69c-.39 4.4 3.56 7.79 9.16 3.97" /></svg>
                                                </div>

                                                {/* <!-- Name --> */}
                                                <h2 className="font-bold text-lg uppercase text-black">Daniel Polk</h2>

                                                {/* <!-- Dummy Message --> */}
                                                <p className="text-white mt-2">HashStackDevelopers elevated our website to new heights! Their expertise and attention to detail transformed our online presence. Seamless collaboration and exceptional results. 10/10 recommend</p>
                                            </div>
                                        </div>


                                    </div>

                                    {/* rating ends */}
                                </div>
                            </div>
                        </section></div>
                    </div>

                    <div style={{ background: "none" }}  >
                        <div style={{ background: "none" }} >  <section className="  text-black body-font">
                            <div className="container px-5 py-10 mx-auto w-auto md:w-[50%] lg:w-[60%] ">
                                {/* <div className="xl:w-1/2 lg:w-3/4 w-full mx-auto text-center"> */}
                                <div className="xl:w-auto lg:w-auto w-full mx-auto text-center">
                                    {/* rating starts */}
                                    <div className='' >
                                        <div className="bg-yellow-600 rounded-lg p-10  shadow-xl  overflow-hidden mx-auto"  >
                                            {/* <!-- Round Image --> */}
                                            <div className="flex justify-center items-center mt-4">
                                                {/* <!-- Placeholder image from Lorem Picsum --> */}
                                                <img src={img3} alt="" className="rounded-full h-20 w-20" />
                                            </div>

                                            {/* <!-- Stars, Name, and Message --> */}
                                            <div className="text-center px-6 py-4">
                                                {/* <!-- Stars (Adjust the value of 'rating' accordingly) --> */}
                                                <div className="flex justify-center mb-2">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 128 128"><path fill="#fdd835" d="m68.05 7.23l13.46 30.7a7.047 7.047 0 0 0 5.82 4.19l32.79 2.94c3.71.54 5.19 5.09 2.5 7.71l-24.7 20.75c-2 1.68-2.91 4.32-2.36 6.87l7.18 33.61c.63 3.69-3.24 6.51-6.56 4.76L67.56 102a7.033 7.033 0 0 0-7.12 0l-28.62 16.75c-3.31 1.74-7.19-1.07-6.56-4.76l7.18-33.61c.54-2.55-.36-5.19-2.36-6.87L5.37 52.78c-2.68-2.61-1.2-7.17 2.5-7.71l32.79-2.94a7.047 7.047 0 0 0 5.82-4.19l13.46-30.7c1.67-3.36 6.45-3.36 8.11-.01" /><path fill="#ffff8d" d="m67.07 39.77l-2.28-22.62c-.09-1.26-.35-3.42 1.67-3.42c1.6 0 2.47 3.33 2.47 3.33l6.84 18.16c2.58 6.91 1.52 9.28-.97 10.68c-2.86 1.6-7.08.35-7.73-6.13" /><path fill="#f4b400" d="M95.28 71.51L114.9 56.2c.97-.81 2.72-2.1 1.32-3.57c-1.11-1.16-4.11.51-4.11.51l-17.17 6.71c-5.12 1.77-8.52 4.39-8.82 7.69c-.39 4.4 3.56 7.79 9.16 3.97" /></svg>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 128 128"><path fill="#fdd835" d="m68.05 7.23l13.46 30.7a7.047 7.047 0 0 0 5.82 4.19l32.79 2.94c3.71.54 5.19 5.09 2.5 7.71l-24.7 20.75c-2 1.68-2.91 4.32-2.36 6.87l7.18 33.61c.63 3.69-3.24 6.51-6.56 4.76L67.56 102a7.033 7.033 0 0 0-7.12 0l-28.62 16.75c-3.31 1.74-7.19-1.07-6.56-4.76l7.18-33.61c.54-2.55-.36-5.19-2.36-6.87L5.37 52.78c-2.68-2.61-1.2-7.17 2.5-7.71l32.79-2.94a7.047 7.047 0 0 0 5.82-4.19l13.46-30.7c1.67-3.36 6.45-3.36 8.11-.01" /><path fill="#ffff8d" d="m67.07 39.77l-2.28-22.62c-.09-1.26-.35-3.42 1.67-3.42c1.6 0 2.47 3.33 2.47 3.33l6.84 18.16c2.58 6.91 1.52 9.28-.97 10.68c-2.86 1.6-7.08.35-7.73-6.13" /><path fill="#f4b400" d="M95.28 71.51L114.9 56.2c.97-.81 2.72-2.1 1.32-3.57c-1.11-1.16-4.11.51-4.11.51l-17.17 6.71c-5.12 1.77-8.52 4.39-8.82 7.69c-.39 4.4 3.56 7.79 9.16 3.97" /></svg>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 128 128"><path fill="#fdd835" d="m68.05 7.23l13.46 30.7a7.047 7.047 0 0 0 5.82 4.19l32.79 2.94c3.71.54 5.19 5.09 2.5 7.71l-24.7 20.75c-2 1.68-2.91 4.32-2.36 6.87l7.18 33.61c.63 3.69-3.24 6.51-6.56 4.76L67.56 102a7.033 7.033 0 0 0-7.12 0l-28.62 16.75c-3.31 1.74-7.19-1.07-6.56-4.76l7.18-33.61c.54-2.55-.36-5.19-2.36-6.87L5.37 52.78c-2.68-2.61-1.2-7.17 2.5-7.71l32.79-2.94a7.047 7.047 0 0 0 5.82-4.19l13.46-30.7c1.67-3.36 6.45-3.36 8.11-.01" /><path fill="#ffff8d" d="m67.07 39.77l-2.28-22.62c-.09-1.26-.35-3.42 1.67-3.42c1.6 0 2.47 3.33 2.47 3.33l6.84 18.16c2.58 6.91 1.52 9.28-.97 10.68c-2.86 1.6-7.08.35-7.73-6.13" /><path fill="#f4b400" d="M95.28 71.51L114.9 56.2c.97-.81 2.72-2.1 1.32-3.57c-1.11-1.16-4.11.51-4.11.51l-17.17 6.71c-5.12 1.77-8.52 4.39-8.82 7.69c-.39 4.4 3.56 7.79 9.16 3.97" /></svg>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 128 128"><path fill="#fdd835" d="m68.05 7.23l13.46 30.7a7.047 7.047 0 0 0 5.82 4.19l32.79 2.94c3.71.54 5.19 5.09 2.5 7.71l-24.7 20.75c-2 1.68-2.91 4.32-2.36 6.87l7.18 33.61c.63 3.69-3.24 6.51-6.56 4.76L67.56 102a7.033 7.033 0 0 0-7.12 0l-28.62 16.75c-3.31 1.74-7.19-1.07-6.56-4.76l7.18-33.61c.54-2.55-.36-5.19-2.36-6.87L5.37 52.78c-2.68-2.61-1.2-7.17 2.5-7.71l32.79-2.94a7.047 7.047 0 0 0 5.82-4.19l13.46-30.7c1.67-3.36 6.45-3.36 8.11-.01" /><path fill="#ffff8d" d="m67.07 39.77l-2.28-22.62c-.09-1.26-.35-3.42 1.67-3.42c1.6 0 2.47 3.33 2.47 3.33l6.84 18.16c2.58 6.91 1.52 9.28-.97 10.68c-2.86 1.6-7.08.35-7.73-6.13" /><path fill="#f4b400" d="M95.28 71.51L114.9 56.2c.97-.81 2.72-2.1 1.32-3.57c-1.11-1.16-4.11.51-4.11.51l-17.17 6.71c-5.12 1.77-8.52 4.39-8.82 7.69c-.39 4.4 3.56 7.79 9.16 3.97" /></svg>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 128 128"><path fill="#fdd835" d="m68.05 7.23l13.46 30.7a7.047 7.047 0 0 0 5.82 4.19l32.79 2.94c3.71.54 5.19 5.09 2.5 7.71l-24.7 20.75c-2 1.68-2.91 4.32-2.36 6.87l7.18 33.61c.63 3.69-3.24 6.51-6.56 4.76L67.56 102a7.033 7.033 0 0 0-7.12 0l-28.62 16.75c-3.31 1.74-7.19-1.07-6.56-4.76l7.18-33.61c.54-2.55-.36-5.19-2.36-6.87L5.37 52.78c-2.68-2.61-1.2-7.17 2.5-7.71l32.79-2.94a7.047 7.047 0 0 0 5.82-4.19l13.46-30.7c1.67-3.36 6.45-3.36 8.11-.01" /><path fill="#ffff8d" d="m67.07 39.77l-2.28-22.62c-.09-1.26-.35-3.42 1.67-3.42c1.6 0 2.47 3.33 2.47 3.33l6.84 18.16c2.58 6.91 1.52 9.28-.97 10.68c-2.86 1.6-7.08.35-7.73-6.13" /><path fill="#f4b400" d="M95.28 71.51L114.9 56.2c.97-.81 2.72-2.1 1.32-3.57c-1.11-1.16-4.11.51-4.11.51l-17.17 6.71c-5.12 1.77-8.52 4.39-8.82 7.69c-.39 4.4 3.56 7.79 9.16 3.97" /></svg>

                                                </div>

                                                {/* <!-- Name --> */}
                                                <h2 className="font-bold text-lg uppercase text-black">Shameer Ahsan</h2>

                                                {/* <!-- Dummy Message --> */}
                                                <p className="text-white mt-2">Social media success! They deliver best results for brand....., Seamless collaboration and exceptional results. 10/10 recommend</p>
                                            </div>
                                        </div>


                                    </div>

                                    {/* rating ends */}
                                </div>
                            </div>
                        </section></div>
                    </div>

                    <div style={{ background: "none" }}  >
                        <div style={{ background: "none" }} >  <section className="  text-black body-font">
                            <div className="container px-5 py-10 mx-auto w-auto md:w-[50%] lg:w-[60%] ">
                                {/* <div className="xl:w-1/2 lg:w-3/4 w-full mx-auto text-center"> */}
                                <div className="xl:w-auto lg:w-auto w-full mx-auto text-center">
                                    {/* rating starts */}
                                    <div className='' >
                                        <div className="bg-yellow-600 rounded-lg p-10  shadow-xl  overflow-hidden mx-auto"  >
                                            {/* <!-- Round Image --> */}
                                            <div className="flex justify-center items-center mt-4">
                                                {/* <!-- Placeholder image from Lorem Picsum --> */}
                                                <img src={img4} alt="" className="rounded-full h-20 w-20" />
                                            </div>

                                            {/* <!-- Stars, Name, and Message --> */}
                                            <div className="text-center px-6 py-4">
                                                {/* <!-- Stars (Adjust the value of 'rating' accordingly) --> */}
                                                <div className="flex justify-center mb-2">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 128 128"><path fill="#fdd835" d="m68.05 7.23l13.46 30.7a7.047 7.047 0 0 0 5.82 4.19l32.79 2.94c3.71.54 5.19 5.09 2.5 7.71l-24.7 20.75c-2 1.68-2.91 4.32-2.36 6.87l7.18 33.61c.63 3.69-3.24 6.51-6.56 4.76L67.56 102a7.033 7.033 0 0 0-7.12 0l-28.62 16.75c-3.31 1.74-7.19-1.07-6.56-4.76l7.18-33.61c.54-2.55-.36-5.19-2.36-6.87L5.37 52.78c-2.68-2.61-1.2-7.17 2.5-7.71l32.79-2.94a7.047 7.047 0 0 0 5.82-4.19l13.46-30.7c1.67-3.36 6.45-3.36 8.11-.01" /><path fill="#ffff8d" d="m67.07 39.77l-2.28-22.62c-.09-1.26-.35-3.42 1.67-3.42c1.6 0 2.47 3.33 2.47 3.33l6.84 18.16c2.58 6.91 1.52 9.28-.97 10.68c-2.86 1.6-7.08.35-7.73-6.13" /><path fill="#f4b400" d="M95.28 71.51L114.9 56.2c.97-.81 2.72-2.1 1.32-3.57c-1.11-1.16-4.11.51-4.11.51l-17.17 6.71c-5.12 1.77-8.52 4.39-8.82 7.69c-.39 4.4 3.56 7.79 9.16 3.97" /></svg>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 128 128"><path fill="#fdd835" d="m68.05 7.23l13.46 30.7a7.047 7.047 0 0 0 5.82 4.19l32.79 2.94c3.71.54 5.19 5.09 2.5 7.71l-24.7 20.75c-2 1.68-2.91 4.32-2.36 6.87l7.18 33.61c.63 3.69-3.24 6.51-6.56 4.76L67.56 102a7.033 7.033 0 0 0-7.12 0l-28.62 16.75c-3.31 1.74-7.19-1.07-6.56-4.76l7.18-33.61c.54-2.55-.36-5.19-2.36-6.87L5.37 52.78c-2.68-2.61-1.2-7.17 2.5-7.71l32.79-2.94a7.047 7.047 0 0 0 5.82-4.19l13.46-30.7c1.67-3.36 6.45-3.36 8.11-.01" /><path fill="#ffff8d" d="m67.07 39.77l-2.28-22.62c-.09-1.26-.35-3.42 1.67-3.42c1.6 0 2.47 3.33 2.47 3.33l6.84 18.16c2.58 6.91 1.52 9.28-.97 10.68c-2.86 1.6-7.08.35-7.73-6.13" /><path fill="#f4b400" d="M95.28 71.51L114.9 56.2c.97-.81 2.72-2.1 1.32-3.57c-1.11-1.16-4.11.51-4.11.51l-17.17 6.71c-5.12 1.77-8.52 4.39-8.82 7.69c-.39 4.4 3.56 7.79 9.16 3.97" /></svg>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 128 128"><path fill="#fdd835" d="m68.05 7.23l13.46 30.7a7.047 7.047 0 0 0 5.82 4.19l32.79 2.94c3.71.54 5.19 5.09 2.5 7.71l-24.7 20.75c-2 1.68-2.91 4.32-2.36 6.87l7.18 33.61c.63 3.69-3.24 6.51-6.56 4.76L67.56 102a7.033 7.033 0 0 0-7.12 0l-28.62 16.75c-3.31 1.74-7.19-1.07-6.56-4.76l7.18-33.61c.54-2.55-.36-5.19-2.36-6.87L5.37 52.78c-2.68-2.61-1.2-7.17 2.5-7.71l32.79-2.94a7.047 7.047 0 0 0 5.82-4.19l13.46-30.7c1.67-3.36 6.45-3.36 8.11-.01" /><path fill="#ffff8d" d="m67.07 39.77l-2.28-22.62c-.09-1.26-.35-3.42 1.67-3.42c1.6 0 2.47 3.33 2.47 3.33l6.84 18.16c2.58 6.91 1.52 9.28-.97 10.68c-2.86 1.6-7.08.35-7.73-6.13" /><path fill="#f4b400" d="M95.28 71.51L114.9 56.2c.97-.81 2.72-2.1 1.32-3.57c-1.11-1.16-4.11.51-4.11.51l-17.17 6.71c-5.12 1.77-8.52 4.39-8.82 7.69c-.39 4.4 3.56 7.79 9.16 3.97" /></svg>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 128 128"><path fill="#fdd835" d="m68.05 7.23l13.46 30.7a7.047 7.047 0 0 0 5.82 4.19l32.79 2.94c3.71.54 5.19 5.09 2.5 7.71l-24.7 20.75c-2 1.68-2.91 4.32-2.36 6.87l7.18 33.61c.63 3.69-3.24 6.51-6.56 4.76L67.56 102a7.033 7.033 0 0 0-7.12 0l-28.62 16.75c-3.31 1.74-7.19-1.07-6.56-4.76l7.18-33.61c.54-2.55-.36-5.19-2.36-6.87L5.37 52.78c-2.68-2.61-1.2-7.17 2.5-7.71l32.79-2.94a7.047 7.047 0 0 0 5.82-4.19l13.46-30.7c1.67-3.36 6.45-3.36 8.11-.01" /><path fill="#ffff8d" d="m67.07 39.77l-2.28-22.62c-.09-1.26-.35-3.42 1.67-3.42c1.6 0 2.47 3.33 2.47 3.33l6.84 18.16c2.58 6.91 1.52 9.28-.97 10.68c-2.86 1.6-7.08.35-7.73-6.13" /><path fill="#f4b400" d="M95.28 71.51L114.9 56.2c.97-.81 2.72-2.1 1.32-3.57c-1.11-1.16-4.11.51-4.11.51l-17.17 6.71c-5.12 1.77-8.52 4.39-8.82 7.69c-.39 4.4 3.56 7.79 9.16 3.97" /></svg>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 128 128"><path fill="#fdd835" d="m68.05 7.23l13.46 30.7a7.047 7.047 0 0 0 5.82 4.19l32.79 2.94c3.71.54 5.19 5.09 2.5 7.71l-24.7 20.75c-2 1.68-2.91 4.32-2.36 6.87l7.18 33.61c.63 3.69-3.24 6.51-6.56 4.76L67.56 102a7.033 7.033 0 0 0-7.12 0l-28.62 16.75c-3.31 1.74-7.19-1.07-6.56-4.76l7.18-33.61c.54-2.55-.36-5.19-2.36-6.87L5.37 52.78c-2.68-2.61-1.2-7.17 2.5-7.71l32.79-2.94a7.047 7.047 0 0 0 5.82-4.19l13.46-30.7c1.67-3.36 6.45-3.36 8.11-.01" /><path fill="#ffff8d" d="m67.07 39.77l-2.28-22.62c-.09-1.26-.35-3.42 1.67-3.42c1.6 0 2.47 3.33 2.47 3.33l6.84 18.16c2.58 6.91 1.52 9.28-.97 10.68c-2.86 1.6-7.08.35-7.73-6.13" /><path fill="#f4b400" d="M95.28 71.51L114.9 56.2c.97-.81 2.72-2.1 1.32-3.57c-1.11-1.16-4.11.51-4.11.51l-17.17 6.71c-5.12 1.77-8.52 4.39-8.82 7.69c-.39 4.4 3.56 7.79 9.16 3.97" /></svg>

                                                </div>

                                                {/* <!-- Name --> */}
                                                <h2 className="font-bold text-lg uppercase text-black">Amber Ghalib</h2>

                                                {/* <!-- Dummy Message --> */}
                                                <p className="text-white mt-2">Where creativity meets excellence. Their graphic designs are simply unmatched. Highly recommended HashStackDevelopers</p>
                                            </div>
                                        </div>


                                    </div>

                                    {/* rating ends */}
                                </div>
                            </div>
                        </section></div>
                    </div>






                </AutoplaySlider>
            </div>


            {/* slider ends */}

            <div className='block md:hidden' >
                <br /><br /><br /><br /><br />
            </div>
            <div className='hidden md:block' >
                <br /><br /><br /><br /><br /><br />
            </div>


            {/* Counter starts */}
            <div div className='' >
                <section className="text-black body-font  md:h-[100vh] ">
                    <div className="container px-5 mx-auto">

                        {/*  */}

                        <div data-aos="zoom-in" data-aos-easing="ease-out-cubic"
                            data-aos-duration="3000" className='  ' >  <div className="flex justify-center items-center text-5xl md:text-6xl text-black " > <span className="    " ><hr className=" w-14 md:w-20  border-2 border-yellow-600 " /></span> Counter <span className="" ><hr className="  w-14 md:w-20 border-2  border-yellow-600 " /></span></div>
                        </div>
                        {/*  */}
                        <div className='block md:hidden' >
                            <br /><br />
                        </div>
                        <div className='hidden md:block' >
                            <br /><br />
                        </div>

                        <div className="flex flex-col text-center w-full ">
                            {/* <h1 className="sm:text-3xl text-2xl font-medium title-font mb-4 text-gray-900">Master Cleanse Reliac Heirloom</h1> */}

                            <p data-aos="fade-in" data-aos-easing="ease-out-cubic"
                                data-aos-duration="3000" className="lg:w-2/3 mx-auto leading-relaxed text-base text-black"> With a proven track record, HashStackDevelopers has successfully completed many projects across many industries. Our products demonstrate many achievements, demonstrating our ability to provide custom solutions that meet and exceed our customers' expectations. Rely on our proven experts to bring your projects to life with precision and innovation.
                            </p>
                        </div>

                        <div className='block md:hidden' >
                            <br /><br /><br />
                        </div>
                        <div className='hidden md:block' >
                            <br /><br /><br />
                        </div>


                        <div className="flex flex-wrap -m-4 text-center">
                            <div className="  p-4 md:w-1/4 sm:w-1/2 w-full">
                                <div data-aos="fade-right" data-aos-easing="ease-out-cubic"
                                    data-aos-duration="3000" className="counter1 relative border-2 bg-yellow-600 border-gray-200 px-4 py-6 rounded-lg">
                                    <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="text-gray-900 w-12 h-12 mb-3 inline-block" viewBox="0 0 24 24">
                                        <path d="M8 17l4 4 4-4m-4-5v9"></path>
                                        <path d="M20.88 18.09A5 5 0 0018 9h-1.26A8 8 0 103 16.29"></path>
                                    </svg>
                                    <h2 className="title-font font-medium text-3xl text-gray-900">
                                        <div className='inline-block' >
                                            <CountUp end={200} redraw={true}>
                                                {({
                                                    countUpRef, start
                                                }) => (
                                                    <VisibilitySensor onChange={start} delayedcall>
                                                        <span ref={countUpRef} />
                                                    </VisibilitySensor>
                                                )}
                                            </CountUp>
                                        </div>+</h2>
                                    {/* </div></h2> */}
                                    <p className="leading-relaxed text-white">Projects </p>
                                </div>
                            </div>
                            <div className="p-4 md:w-1/4 sm:w-1/2 w-full">
                                <div data-aos="fade-up" data-aos-easing="ease-out-cubic"
                                    data-aos-duration="3000" className=" counter1 relative border-2 bg-yellow-600 border-gray-200 px-4 py-6 rounded-lg">
                                    <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="text-gray-900 w-12 h-12 mb-3 inline-block" viewBox="0 0 24 24">
                                        <path d="M17 21v-2a4 4 0 00-4-4H5a4 4 0 00-4 4v2"></path>
                                        <circle cx="9" cy="7" r="4"></circle>
                                        <path d="M23 21v-2a4 4 0 00-3-3.87m-4-12a4 4 0 010 7.75"></path>
                                    </svg>
                                    <h2 className="title-font font-medium text-3xl text-gray-900">    <CountUp end={190} redraw={true}>
                                        {({
                                            countUpRef, start
                                        }) => (
                                            <VisibilitySensor onChange={start} delayedcall>
                                                <span ref={countUpRef} />
                                            </VisibilitySensor>
                                        )}
                                    </CountUp>
                                        {/* K */}
                                        +
                                    </h2>
                                    <p className="leading-relaxed text-white">Happy Customer</p>
                                </div>
                            </div>
                            <div className="p-4 md:w-1/4 sm:w-1/2 w-full">
                                <div data-aos="fade-up" data-aos-easing="ease-out-cubic"
                                    data-aos-duration="3000" className=" counter1 relative border-2 bg-yellow-600 border-gray-200 px-4 py-6 rounded-lg">
                                    <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="text-gray-900 w-12 h-12 mb-3 inline-block" viewBox="0 0 24 24">
                                        <path d="M3 18v-6a9 9 0 0118 0v6"></path>
                                        <path d="M21 19a2 2 0 01-2 2h-1a2 2 0 01-2-2v-3a2 2 0 012-2h3zM3 19a2 2 0 002 2h1a2 2 0 002-2v-3a2 2 0 00-2-2H3z"></path>
                                    </svg>
                                    <h2 className="title-font font-medium text-3xl text-gray-900">    <CountUp end={6} redraw={true}>
                                        {({
                                            countUpRef, start
                                        }) => (
                                            <VisibilitySensor onChange={start} delayedcall>
                                                <span ref={countUpRef} />
                                            </VisibilitySensor>
                                        )}
                                    </CountUp>+</h2>
                                    <p className="leading-relaxed text-white">Departments </p>
                                </div>
                            </div>
                            <div className="p-4 md:w-1/4 sm:w-1/2 w-full">
                                <div data-aos="fade-left" data-aos-easing="ease-out-cubic"
                                    data-aos-duration="3000" className="counter1 relative border-2 bg-yellow-600 border-gray-200 px-4 py-6 rounded-lg">
                                    <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="text-gray-900 w-12 h-12 mb-3 inline-block" viewBox="0 0 24 24">
                                        <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10z"></path>
                                    </svg>
                                    <h2 className="title-font font-medium text-3xl text-gray-900">    <CountUp end={25} redraw={true}>
                                        {({
                                            countUpRef, start
                                        }) => (
                                            <VisibilitySensor onChange={start} delayedcall>
                                                <span ref={countUpRef} />
                                            </VisibilitySensor>
                                        )}
                                    </CountUp>+</h2>
                                    <p className="leading-relaxed text-white">Employee</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div >
            {/* Counter ends */}

            {/* Parallex effect starts */}

            <br /><br />
            <div div className='  flex justify-center  pr-5 pl-5 md:pl-0 md:pr-0' >
                <div >
                    <div className='block md:hidden relative ' >
                        <img className='relative' data-aos="fade-up" data-aos-easing="ease-out-cubic"
                            data-aos-duration="3000" src={img8} alt="" />
                        {/* <img src={img3} alt="" /> */}
                    </div>
                    <div className='hidden md:block '>
                        <div className='flex relative ' >
                            <img data-aos="fade-right" data-aos-easing="ease-out-cubic"
                                data-aos-duration="3000" className='relative' src={img7} alt="" />
                            <img data-aos="fade-up" data-aos-easing="ease-out-cubic"
                                data-aos-duration="3000" className='relative' src={img5} alt="" />

                        </div>
                    </div>
                </div>
            </div >
            {/* Parallex effect ends */}
            <br /><br />
            {/* testimonial 2 starts */}

            <br /><br /><br />
            <div data-aos="zoom-in" data-aos-easing="ease-out-cubic"
                data-aos-duration="3000" className='' >  <div className="flex justify-center items-center text-5xl md:text-6xl text-black " > <span className="    " ><hr className=" w-14 md:w-20  border-2 border-yellow-600 " /></span> Partners <span className="" ><hr className="  w-14 md:w-20 border-2  border-yellow-600 " /></span></div>
            </div>
            {/*  */}

            {/*  */}
            {/* <p className="lg:w-2/3 mx-auto leading-relaxed text-base pt-10">Whatever cardigan tote bag tumblr hexagon brooklyn asymmetrical gentrify, subway tile poke farm-to-table. Franzen you probably haven't heard of them.</p> */}
            <br /><br /><br />


            <div className='bg-white h-[100vh] flex ' >


                {/* <AwesomeSlider className='bg-white  ' bullets={false} interval={1000} infinite={true} autoplay={true} startup={true} buttons={true} >
                    <div style={{ background: "white" }} >  <section className="text-black body-font">
                        <div className="container px-5 py-10 mx-auto">
                            <div className="xl:w-1/2 lg:w-3/4 w-full mx-auto text-center">
                                
                                <img alt="testimonial" className="w-32 h-32 mb-8 object-cover object-center rounded-full inline-block border-2 border-gray-200 bg-gray-100" src={img6}></img>
                                <p className="leading-relaxed text-lg">Edison  cleanse direct trade indigo juice before they sold out gentrify plaid gastropub normcore XOXO 90's pickled cindigo jean shorts. Slow-carb next level shoindigoitch ethical authentic, yr scenester sriracha forage franzen organic drinking vinegar.</p>
                                <span className="inline-block h-1 w-10 rounded bg-yellow-600 mt-8 mb-6"></span>
                                <h2 className="  text-gray-900 font-medium title-font tracking-wider text-sm">HOLDEN CAULFIELD</h2>
                                <p className="  text-gray-500">Senior Product Designer</p>
                            </div>
                        </div>
                    </section></div>
                    <div style={{ background: "white" }} >  <section className="text-black body-font">
                        <div className="container px-5 py-10 mx-auto">
                            <div className="xl:w-1/2 lg:w-3/4 w-full mx-auto text-center">
                               
                                <img alt="testimonial" className="w-32 h-32 mb-8 object-cover object-center rounded-full inline-block border-2 border-gray-200 bg-gray-100" src={img6}></img>
                                <p className="leading-relaxed text-lg">Edison  cleanse direct trade indigo juice before they sold out gentrify plaid gastropub normcore XOXO 90's pickled cindigo jean shorts. Slow-carb next level shoindigoitch ethical authentic, yr scenester sriracha forage franzen organic drinking vinegar.</p>
                                <span className="inline-block h-1 w-10 rounded bg-yellow-600 mt-8 mb-6"></span>
                                <h2 className="  text-gray-900 font-medium title-font tracking-wider text-sm">HOLDEN CAULFIELD</h2>
                                <p className="  text-gray-500">Senior Product Designer</p>
                            </div>
                        </div>
                    </section></div>
                    <div style={{ background: "white" }}  >  <section className="text-black body-font">
                        <div className="container px-5 py-10 mx-auto">
                            <div className="xl:w-1/2 lg:w-3/4 w-full mx-auto text-center">
                            
                                <img alt="testimonial" className="w-32 h-32 mb-8 object-cover object-center rounded-full inline-block border-2 border-gray-200 bg-gray-100" src={img6}></img>
                                <p className="leading-relaxed text-lg">Edison  cleanse direct trade indigo juice before they sold out gentrify plaid gastropub normcore XOXO 90's pickled cindigo jean shorts. Slow-carb next level shoindigoitch ethical authentic, yr scenester sriracha forage franzen organic drinking vinegar.</p>
                                <span className="inline-block h-1 w-10 rounded bg-yellow-600 mt-8 mb-6"></span>
                                <h2 className="  text-gray-900 font-medium title-font tracking-wider text-sm">HOLDEN CAULFIELD</h2>
                                <p className="  text-gray-500">Senior Product Designer</p>
                            </div>
                        </div>
                    </section></div>
                    <div style={{ background: "white" }} >  <section className="text-black body-font">
                        <div className="container px-5 py-10 mx-auto">
                            <div className="xl:w-1/2 lg:w-3/4 w-full mx-auto text-center">
                               
                                <img alt="testimonial" className="w-32 h-32 mb-8 object-cover object-center rounded-full inline-block border-2 border-gray-200 bg-gray-100" src={img6}></img>
                                <p className="leading-relaxed text-lg">Edison  cleanse direct trade indigo juice before they sold out gentrify plaid gastropub normcore XOXO 90's pickled cindigo jean shorts. Slow-carb next level shoindigoitch ethical authentic, yr scenester sriracha forage franzen organic drinking vinegar.</p>
                                <span className="inline-block h-1 w-10 rounded bg-yellow-600 mt-8 mb-6"></span>
                                <h2 className="  text-gray-900 font-medium title-font tracking-wider text-sm">HOLDEN CAULFIELD</h2>
                                <p className="  text-gray-500">Senior Product Designer</p>
                            </div>
                        </div>
                    </section></div>
                </AwesomeSlider> */}

                <AutoplaySlider
                    play={true}
                    cancelOnInteraction={false} // should stop playing on user interaction
                    interval={5000}
                    bullets={false}
                    fillParent={false}
                    buttons={false}
                >

                    <div style={{ background: "none" }} >
                        <div style={{ background: "none" }} >  <section className="  text-black body-font">
                            <div className="container px-5 py-10 mx-auto">
                                <div className="xl:w-1/2 lg:w-3/4 w-full mx-auto text-center">

                                    <img alt="testimonial" className="w-32 h-32 mb-8 object-cover object-center rounded-full inline-block border-2 border-gray-200 bg-gray-100" src={img9}></img>
                                    <p className="leading-relaxed text-lg">Programmers Logic is a technology development center where a team of developers, designers and engineers work together to create the best solutions. These companies act as architects of the digital world, transforming complex ideas into powerful consumer products, improving user experience and efficiency.</p>
                                    <span className="inline-block h-1 w-10 rounded bg-yellow-600 mt-8 mb-6"></span>
                                    <h2 className="  text-gray-900 font-medium title-font tracking-wider text-sm">PROGRAMMER LOGICS</h2>
                                    <p className="  text-gray-500">Rana Zain</p>
                                </div>
                            </div>
                        </section></div>
                    </div>
                    {/* <div style={{ background: "none" }} >
                        <div style={{ background: "white" }} >  <section className="text-black body-font">
                            <div className="container px-5 py-10 mx-auto">
                                <div className="xl:w-1/2 lg:w-3/4 w-full mx-auto text-center">

                                    <img alt="testimonial" className="w-32 h-32 mb-8 object-cover object-center rounded-full inline-block border-2 border-gray-200 bg-gray-100" src={img10}></img>
                                    <p className="leading-relaxed text-lg">Edison  cleanse direct trade indigo juice before they sold out gentrify plaid gastropub normcore XOXO 90's pickled cindigo jean shorts. Slow-carb next level shoindigoitch ethical authentic, yr scenester sriracha forage franzen organic drinking vinegar.</p>
                                    <span className="inline-block h-1 w-10 rounded bg-yellow-600 mt-8 mb-6"></span>
                                    <h2 className="  text-gray-900 font-medium title-font tracking-wider text-sm">FOURDEVS</h2>
                                    <p className="  text-gray-500"> Haris Mahmood</p>
                                </div>
                            </div>
                        </section></div>
                    </div> */}
                    <div style={{ background: "none" }} >
                        <div style={{ background: "none" }} >  <section className="text-black body-font">
                            <div className="container px-5 py-10 mx-auto">
                                <div className="xl:w-1/2 lg:w-3/4 w-full mx-auto text-center">

                                    <img alt="testimonial" className="w-32 h-32 mb-8 object-cover object-center rounded-full inline-block border-2 border-gray-200 bg-gray-100" src={img6}></img>
                                    <p className="leading-relaxed text-lg">Our team is experienced in software development and is ready to help develop your chosen application. We take care to create custom software solutions for you to facilitate your business processes and increase profitability.</p>
                                    <span className="inline-block h-1 w-10 rounded bg-yellow-600 mt-8 mb-6"></span>
                                    <h2 className="  text-gray-900 font-medium title-font tracking-wider text-sm">Codevo Technologies</h2>
                                    <p className="  text-gray-500">Mohsin Naqvi</p>
                                </div>
                            </div>
                        </section></div>
                    </div>
                    {/* <div style={{ background: "none" }} >
                        <div style={{ background: "none" }} >  <section className="text-black body-font">
                            <div className="container px-5 py-10 mx-auto">
                                <div className="xl:w-1/2 lg:w-3/4 w-full mx-auto text-center">

                                    <img alt="testimonial" className="w-32 h-32 mb-8 object-cover object-center rounded-full inline-block border-2 border-gray-200 bg-gray-100" src={img11}></img>
                                    <p className="leading-relaxed text-lg">Within the walls of the software company, lines of code come to life and weave an intricate tapestry of technology. Like the music of algorithms and designs, these companies drive innovation and create software solutions that support businesses, connect communities and foster progress.</p>
                                    <span className="inline-block h-1 w-10 rounded bg-yellow-600 mt-8 mb-6"></span>
                                    <h2 className="  text-gray-900 font-medium title-font tracking-wider text-sm">TECNO BRAINZ</h2>
                                    <p className="  text-gray-500">Syed Ali</p>
                                </div>
                            </div>
                        </section></div>
                    </div> */}
                    {/* <div style={{ background: "none" }} >
                        <div style={{ background: "none" }} >  <section className="text-black body-font">
                            <div className="container px-5 py-10 mx-auto">
                                <div className="xl:w-1/2 lg:w-3/4 w-full mx-auto text-center">

                                    <img alt="testimonial" className="w-32 h-32 mb-8 object-cover object-center rounded-full inline-block border-2 border-gray-200 bg-gray-100" src={img12}></img>
                                    <p className="leading-relaxed text-lg">Edison  cleanse direct trade indigo juice before they sold out gentrify plaid gastropub normcore XOXO 90's pickled cindigo jean shorts. Slow-carb next level shoindigoitch ethical authentic, yr scenester sriracha forage franzen organic drinking vinegar.</p>
                                    <span className="inline-block h-1 w-10 rounded bg-yellow-600 mt-8 mb-6"></span>
                                    <h2 className="  text-gray-900 font-medium title-font tracking-wider text-sm">BLINKERS DEVELOPER</h2>
                                    <p className="  text-gray-500">Muhammad Junaid</p>
                                </div>
                            </div>
                        </section></div>
                    </div> */}
                    {/* <div style={{ background: "none" }} >
                        <div style={{ background: "none" }} >  <section className="text-black body-font">
                            <div className="container px-5 py-10 mx-auto">
                                <div className="xl:w-1/2 lg:w-3/4 w-full mx-auto text-center">

                                    <img alt="testimonial" className="w-32 h-32 mb-8 object-cover object-center rounded-full inline-block border-2 border-gray-200 bg-gray-100" src={img13}></img>
                                    <p className="leading-relaxed text-lg">Edison  cleanse direct trade indigo juice before they sold out gentrify plaid gastropub normcore XOXO 90's pickled cindigo jean shorts. Slow-carb next level shoindigoitch ethical authentic, yr scenester sriracha forage franzen organic drinking vinegar.</p>
                                    <span className="inline-block h-1 w-10 rounded bg-yellow-600 mt-8 mb-6"></span>
                                    <h2 className="  text-gray-900 font-medium title-font tracking-wider text-sm">AURLIAN ORIGINS</h2>
                                    <p className="  text-gray-500">SAAF ULLAH SADDIQ</p>
                                </div>
                            </div>
                        </section></div>
                    </div> */}
                    {/* <div style={{ background: "none" }} >
                        <div style={{ background: "none" }} >  <section className="text-black body-font">
                            <div className="container px-5 py-10 mx-auto">
                                <div className="xl:w-1/2 lg:w-3/4 w-full mx-auto text-center">

                                    <img alt="testimonial" className="w-32 h-32 mb-8 object-cover object-center rounded-full inline-block border-2 border-gray-200 bg-gray-100" src={img6}></img>
                                    <p className="leading-relaxed text-lg">Edison  cleanse direct trade indigo juice before they sold out gentrify plaid gastropub normcore XOXO 90's pickled cindigo jean shorts. Slow-carb next level shoindigoitch ethical authentic, yr scenester sriracha forage franzen organic drinking vinegar.</p>
                                    <span className="inline-block h-1 w-10 rounded bg-yellow-600 mt-8 mb-6"></span>
                                    <h2 className="  text-gray-900 font-medium title-font tracking-wider text-sm">EBRYX</h2>
                                    <p className="  text-gray-500">Ahrar Naqvi</p>
                                </div>
                            </div>
                        </section></div>
                    </div> */}
                    {/* <div style={{ background: "none" }} >
                        <div style={{ background: "none" }} >  <section className="text-black body-font">
                            <div className="container px-5 py-10 mx-auto">
                                <div className="xl:w-1/2 lg:w-3/4 w-full mx-auto text-center">

                                    <img alt="testimonial" className="w-32 h-32 mb-8 object-cover object-center rounded-full inline-block border-2 border-gray-200 bg-gray-100" src={img6}></img>
                                    <p className="leading-relaxed text-lg">Edison  cleanse direct trade indigo juice before they sold out gentrify plaid gastropub normcore XOXO 90's pickled cindigo jean shorts. Slow-carb next level shoindigoitch ethical authentic, yr scenester sriracha forage franzen organic drinking vinegar.</p>
                                    <span className="inline-block h-1 w-10 rounded bg-yellow-600 mt-8 mb-6"></span>
                                    <h2 className="  text-gray-900 font-medium title-font tracking-wider text-sm">Abacus Soft</h2>
                                    <p className="  text-gray-500">Sajad Haider</p>
                                </div>
                            </div>
                        </section></div>
                    </div> */}

                </AutoplaySlider>
            </div>


            {/* testimonial 2 ends */}




            {/* slider ends */}


            {/* Footer starts */}
            <Footer />
            {/* Footer ends */}


        </div >
    )
}
