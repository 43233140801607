import React, { useEffect } from 'react'
import SideNav from './SideNav'
import Btn from './Btn'
// import 'bootstrap/dist/css/bootstrap.min.css';
import img1 from "../images/talha2.jpeg"
import img2 from "../images/ahsan.jpeg"
// import img3 from "../images/haseeb4.jpeg"
// import img4 from "../images/image2.jpeg"
// import img9 from "../images/junaid.jpeg"
import img10 from "../images/mateen_updated.jpeg"
import img5 from "../images/image2-removebg-preview.png"
import img7 from "../images/image3-removebg-preview.png"
import img8 from "../images/image4-removebg-preview.png"
import img9 from "../images/muzammil.jpeg"
import img11 from "../images/faizan.jpeg"
import img18 from "../images/abdullah.jpeg"
import team89 from "../images/osama.jpeg"
import img19 from "../images/arooj.jpeg"


import AOS from 'aos';
import 'aos/dist/aos.css';

import Footer from "../components/footer"

import Particle from './Particle'

export default function Teamus() {
    useEffect(() => {
        AOS.init({ duration: 800 });


    }, []);
    return (
        // <div className='w-screen h-screen bg-black' >
        <div>
            <div className='  '>
                <div className='relative z-20' >
                    <SideNav />
                </div>
                <div className='absolute' >
                    <div className='absolute z-10' >
                        <Btn />
                    </div>
                    <Particle />
                </div>

                <div className='bg-black h-screen flex justify-center items-center '>
                    <h1 data-aos="fade-up" data-aos-easing="ease-out-cubic"
                        data-aos-duration="3000" className='text-center text-white text-2xl font-bold '  > <span className='text-5xl text-yellow-600 font-bold'>OUR PORTFOLIO</span> </h1>
                </div>


                {/* <section className="  text-gray-600 body-font">
                    <div className="  container px-5 py-24 justify-around mx-auto flex flex-wrap">
                        <div className="  flex flex-wrap -mx-4 mt-auto mb-auto lg:w-1/2 sm:w-2/3 content-start sm:pr-10">
                            <div className="w-full sm:p-4 px-4 mb-6">
                                <h1 className="title-font font-medium text-2xl mb-2 text-gray-900">Chaudhary Haseeb Mahmood</h1>
                                <div className="leading-relaxed">As CEO, I have the vision to drive innovation and growth. Through cooperation and support, we achieve excellence and customer satisfaction. Together we create new business models and seize the moment.</div>
                            </div>

                        </div>
                        <div className="lg:w-1/4 sm:w-1/3 h-[430px] sm:h-[60vh] w-full rounded-lg overflow-hidden mt-6 sm:mt-0">
                            <img className=" relative object-cover object-center w-full h-full" src={img3} alt="stats" />
                        </div>
                    </div>
                </section> */}
                <div className='block md:hidden' >
                    <br /><br /><br />
                </div>
                <div className='hidden md:block' >
                    <br /><br /><br />
                </div>

                <div data-aos="zoom-in" data-aos-easing="ease-out-cubic"
                    data-aos-duration="3000" className='' >  <div className="flex justify-center items-center text-3xl md:text-6xl " > <span className="    " ><hr className=" w-14 md:w-20  border-2 border-yellow-600 " /></span> Latest Projects <span className="" ><hr className="  w-14 md:w-20 border-2  border-yellow-600 " /></span></div>
                </div>

                <div className='block md:hidden' >
                    <br /><br />
                </div>
                <div className='hidden md:block' >
                    <br /><br /><br />
                </div>
                {/* <div data-aos="zoom-in" data-aos-easing="ease-out-cubic"
                    data-aos-duration="3000" className='pt-16' >  <div className=" flex justify-center  items-center text-xl md:text-4xl " > <span className="    " > <hr className=" w-7 md:w-12  border-2 border-yellow-600 " /> </span> Website Developement <span className="" ><hr className=" w-7 md:w-12  border-2 border-yellow-600 " /></span></div>
                </div> */}
                <div data-aos="flip-up" data-aos-easing="ease-out-cubic"
                    data-aos-duration="3000" className='' >  <div className="text-yellow-600 flex justify-center  items-center text-2xl md:text-4xl " > <span className="    " >  </span> Website Developement <span className="" ></span></div>
                </div>

                <section className="   body-font">
                    <div className="   container px-5 py-24 justify-around mx-auto flex flex-wrap">
                        <div className="  flex flex-wrap -mx-4 mt-auto mb-auto lg:w-1/2 sm:w-2/3 content-start sm:pr-10">
                            <div className="w-full sm:p-4 px-4 mb-6">
                                <div data-aos="flip-up" data-aos-easing="ease-out-cubic"
                                    data-aos-duration="3000" >

                                    <h1 className="title-font font-medium text-2xl mb-2 text-yellow-600">US PIZZA</h1>
                                </div>

                                <div data-aos="fade-in" data-aos-easing="ease-out-cubic"
                                    data-aos-duration="3000" className="leading-relaxed">Embark on a flavor-filled journey at US Pizza, where every bite is crafted with passion and perfection. From our mouthwatering pizzas to our delectable sides, experience culinary bliss in every slice. Join us and indulge in a taste sensation like no other! 🍕✨</div>
                            </div>
                            {/* <div className="p-4 sm:w-1/2 lg:w-1/4 w-1/2">
                                <h2 className="title-font font-medium text-3xl text-gray-900">2.7K</h2>
                                <p className="leading-relaxed">Users</p>
                            </div>
                            <div className="p-4 sm:w-1/2 lg:w-1/4 w-1/2">
                                <h2 className="title-font font-medium text-3xl text-gray-900">1.8K</h2>
                                <p className="leading-relaxed">Subscribes</p>
                            </div>
                            <div className="p-4 sm:w-1/2 lg:w-1/4 w-1/2">
                                <h2 className="title-font font-medium text-3xl text-gray-900">35</h2>
                                <p className="leading-relaxed">Downloads</p>
                            </div>
                            <div className="p-4 sm:w-1/2 lg:w-1/4 w-1/2">
                                <h2 className="title-font font-medium text-3xl text-gray-900">4</h2>
                                <p className="leading-relaxed">Products</p>
                            </div> */}
                        </div>

                        <div data-aos="fade-left" data-aos-easing="ease-out-cubic"
                            data-aos-duration="3000" className="hidden md:block shadow-xl lg:w-1/4 sm:w-1/3 h-[430px] sm:h-[60vh] w-full rounded-lg overflow-hidden mt-6 sm:mt-0">
                            <img className=" relative  object-cover object-cover w-full h-full" src={img10} alt="stats" />
                        </div>

                        <div data-aos="fade-up" data-aos-easing="ease-out-cubic"
                            data-aos-duration="3000" className="block md:hidden shadow-xl lg:w-1/4 sm:w-1/3 h-[430px] sm:h-[60vh] w-full rounded-lg overflow-hidden mt-6 sm:mt-0">
                            <img className=" relative  object-cover object-cover w-full h-full" src={img10} alt="stats" />
                        </div>

                        {/* <div className='block md:hidden' ><div className="shadow-xl lg:w-1/4 sm:w-1/3 h-[430px] sm:h-[60vh] w-full rounded-lg overflow-hidden mt-6 sm:mt-0">
                            <img className=" relative  object-cover object-cover w-full h-full" src={img10} alt="stats" />
                        </div></div> */}

                        {/* <div className="shadow-xl lg:w-1/4 sm:w-1/3 h-[430px] sm:h-[60vh] w-full rounded-lg overflow-hidden mt-6 sm:mt-0">
                            <img className=" relative  object-cover object-cover w-full h-full" src={img10} alt="stats" />
                        </div> */}
                    </div>
                </section>

                <section className="   body-font">
                    <div className=" bg-yellow-600  container px-5 py-24 justify-around mx-auto flex flex-wrap">
                        <div className="  flex flex-wrap -mx-4 mt-auto mb-auto lg:w-1/2 sm:w-2/3 content-start sm:pr-10">
                            <div className="w-full sm:p-4 px-4 mb-6">
                                <div data-aos="flip-up" data-aos-easing="ease-out-cubic"
                                    data-aos-duration="3000" >
                                    <h1 className="title-font font-medium text-2xl mb-2 text-black">ARB COLLECTIONS</h1>
                                </div>
                                <div data-aos="fade-in" data-aos-easing="ease-out-cubic"
                                    data-aos-duration="3000" className="leading-relaxed text-slate-200 ">As the Marketing Manager, I direct the strategic direction of our brand with new business. By understanding the marketing process, I create marketing plans that resonate with my audience. Through creative storytelling and data analytics, I make sure our products are relevant and continue to grow.</div>
                            </div>




                            {/* <div className="p-4 sm:w-1/2 lg:w-1/4 w-1/2">
                                <h2 className="title-font font-medium text-3xl text-gray-900">2.7K</h2>
                                <p className="leading-relaxed">Users</p>
                            </div>
                            <div className="p-4 sm:w-1/2 lg:w-1/4 w-1/2">
                                <h2 className="title-font font-medium text-3xl text-gray-900">1.8K</h2>
                                <p className="leading-relaxed">Subscribes</p>
                            </div>
                            <div className="p-4 sm:w-1/2 lg:w-1/4 w-1/2">
                                <h2 className="title-font font-medium text-3xl text-gray-900">35</h2>
                                <p className="leading-relaxed">Downloads</p>
                            </div>
                            <div className="p-4 sm:w-1/2 lg:w-1/4 w-1/2">
                                <h2 className="title-font font-medium text-3xl text-gray-900">4</h2>
                                <p className="leading-relaxed">Products</p>
                            </div> */}
                        </div>

                        <div data-aos="fade-left" data-aos-easing="ease-out-cubic"
                            data-aos-duration="3000" className="hidden md:block shadow-xl lg:w-1/4 sm:w-1/3 h-[430px] sm:h-[60vh] w-full rounded-lg overflow-hidden mt-6 sm:mt-0">
                            <img className=" relative  object-cover object-cover w-full h-full" src={img1} alt="stats" />
                        </div>

                        <div data-aos="fade-up" data-aos-easing="ease-out-cubic"
                            data-aos-duration="3000" className="block md:hidden shadow-xl lg:w-1/4 sm:w-1/3 h-[430px] sm:h-[60vh] w-full rounded-lg overflow-hidden mt-6 sm:mt-0">
                            <img className=" relative  object-cover object-cover w-full h-full" src={img1} alt="stats" />
                        </div>


                    </div>
                </section>

                <div className='block md:hidden' >
                    <br /><br /><br />
                </div>
                <div className='hidden md:block' >
                    <br /><br /><br />
                </div>
                <div data-aos="flip-up" data-aos-easing="ease-out-cubic"
                    data-aos-duration="3000" className='' >  <div className=" text-yellow-600 flex justify-center  items-center text-2xl md:text-4xl " > <span className="    " ></span>Application Developement <span className="" ></span></div>
                </div>

                <section className="   body-font">
                    <div className="  container px-5 py-24 justify-around mx-auto flex flex-wrap">
                        <div className="  flex flex-wrap -mx-4 mt-auto mb-auto lg:w-1/2 sm:w-2/3 content-start sm:pr-10">
                            <div className="w-full sm:p-4 px-4 mb-6">
                                <div data-aos="flip-up" data-aos-easing="ease-out-cubic"
                                    data-aos-duration="3000" >

                                    <h1 className="title-font font-medium text-2xl mb-2 text-yellow-600">LockID - AppLock & Photo Vault</h1>
                                </div>
                                <div data-aos="fade-in" data-aos-easing="ease-out-cubic"
                                    data-aos-duration="3000" className="leading-relaxed">Secure your privacy with ease – Introducing the ultimate protection for your apps with our App Lock feature. Keep your personal information safe and your peace of mind intact. Download now and take control of your digital security!</div>
                            </div>
                            {/* <div className="p-4 sm:w-1/2 lg:w-1/4 w-1/2">
                                <h2 className="title-font font-medium text-3xl text-gray-900">2.7K</h2>
                                <p className="leading-relaxed">Users</p>
                            </div>
                            <div className="p-4 sm:w-1/2 lg:w-1/4 w-1/2">
                                <h2 className="title-font font-medium text-3xl text-gray-900">1.8K</h2>
                                <p className="leading-relaxed">Subscribes</p>
                            </div>
                            <div className="p-4 sm:w-1/2 lg:w-1/4 w-1/2">
                                <h2 className="title-font font-medium text-3xl text-gray-900">35</h2>
                                <p className="leading-relaxed">Downloads</p>
                            </div>
                            <div className="p-4 sm:w-1/2 lg:w-1/4 w-1/2">
                                <h2 className="title-font font-medium text-3xl text-gray-900">4</h2>
                                <p className="leading-relaxed">Products</p>
                            </div> */}
                        </div>
                        <div data-aos="fade-left" data-aos-easing="ease-out-cubic"
                            data-aos-duration="3000" className="hidden md:block shadow-xl lg:w-1/4 sm:w-1/3 h-[430px] sm:h-[60vh] w-full rounded-lg overflow-hidden mt-6 sm:mt-0">
                            <img className=" relative  object-cover object-cover w-full h-full" src={img2} alt="stats" />
                        </div>

                        <div data-aos="fade-up" data-aos-easing="ease-out-cubic"
                            data-aos-duration="3000" className="block md:hidden shadow-xl lg:w-1/4 sm:w-1/3 h-[430px] sm:h-[60vh] w-full rounded-lg overflow-hidden mt-6 sm:mt-0">
                            <img className=" relative  object-cover object-cover w-full h-full" src={img2} alt="stats" />
                        </div>
                    </div>
                </section>


                <section className="  text-gray-600 body-font">
                    <div className=" bg-yellow-600 container px-5 py-24 justify-around mx-auto flex flex-wrap">
                        <div className="  flex flex-wrap -mx-4 mt-auto mb-auto lg:w-1/2 sm:w-2/3 content-start sm:pr-10">
                            <div className="w-full sm:p-4 px-4 mb-6">
                                <div data-aos="flip-up" data-aos-easing="ease-out-cubic"
                                    data-aos-duration="3000">
                                    <h1 className="title-font font-medium text-2xl mb-2 text-gray-900">VPN Super Speed & Secure</h1>
                                </div>
                                <div data-aos="fade-in" data-aos-easing="ease-out-cubic"
                                    data-aos-duration="3000" className="leading-relaxed text-slate-200">Experience the ultimate online freedom with VPN Super – your passport to secure browsing, unrestricted access, and peace of mind in the digital world. Safeguard your privacy, unlock content, and stay anonymous with just one tap. Join millions of users and supercharge your internet experience today!</div>
                            </div>
                            {/* <div className="p-4 sm:w-1/2 lg:w-1/4 w-1/2">
                                <h2 className="title-font font-medium text-3xl text-gray-900">2.7K</h2>
                                <p className="leading-relaxed">Users</p>
                            </div>
                            <div className="p-4 sm:w-1/2 lg:w-1/4 w-1/2">
                                <h2 className="title-font font-medium text-3xl text-gray-900">1.8K</h2>
                                <p className="leading-relaxed">Subscribes</p>
                            </div>
                            <div className="p-4 sm:w-1/2 lg:w-1/4 w-1/2">
                                <h2 className="title-font font-medium text-3xl text-gray-900">35</h2>
                                <p className="leading-relaxed">Downloads</p>
                            </div>
                            <div className="p-4 sm:w-1/2 lg:w-1/4 w-1/2">
                                <h2 className="title-font font-medium text-3xl text-gray-900">4</h2>
                                <p className="leading-relaxed">Products</p>
                            </div> */}
                        </div>
                        {/* <div className="lg:w-1/4 sm:w-1/3 h-[430px] sm:h-[60vh] w-full rounded-lg overflow-hidden mt-6 sm:mt-0">
                            <img className=" relative object-top object-cover  w-full h-full" src={img9} alt="stats" />
                        </div> */}
                        <div data-aos="fade-left" data-aos-easing="ease-out-cubic"
                            data-aos-duration="3000" className="hidden md:block shadow-xl lg:w-1/4 sm:w-1/3 h-[430px] sm:h-[60vh] w-full rounded-lg overflow-hidden mt-6 sm:mt-0">
                            <img className=" relative  object-cover object-cover w-full h-full" src={img9} alt="stats" />
                        </div>

                        <div data-aos="fade-up" data-aos-easing="ease-out-cubic"
                            data-aos-duration="3000" className="block md:hidden shadow-xl lg:w-1/4 sm:w-1/3 h-[430px] sm:h-[60vh] w-full rounded-lg overflow-hidden mt-6 sm:mt-0">
                            <img className=" relative  object-cover object-cover w-full h-full" src={img9} alt="stats" />
                        </div>
                    </div>
                </section>

                <div className='block md:hidden' >
                    <br /><br />
                </div>
                <div className='hidden md:block' >
                    <br /><br /><br />
                </div>
                <div data-aos="flip-up" data-aos-easing="ease-out-cubic"
                    data-aos-duration="3000" className='' >  <div className=" text-yellow-600 flex justify-center  items-center text-2xl md:text-4xl " > <span className="    " ></span>Social Media Marketing <span className="" ></span></div>
                </div>

                <section className="   body-font">
                    <div className="   container px-5 py-24 justify-around mx-auto flex flex-wrap">
                        <div className="  flex flex-wrap -mx-4 mt-auto mb-auto lg:w-1/2 sm:w-2/3 content-start sm:pr-10">
                            <div className="w-full sm:p-4 px-4 mb-6">
                                <div data-aos="flip-up" data-aos-easing="ease-out-cubic"
                                    data-aos-duration="3000">
                                    <h1 className="title-font font-medium text-2xl mb-2 text-yellow-600">Hunza Elites</h1>
                                </div>
                                <div data-aos="fade-in" data-aos-easing="ease-out-cubic"
                                    data-aos-duration="3000" className="leading-relaxed">Step into luxury with Hunza Elites – Where elegance meets opulence, and every detail exudes sophistication. Discover a world of exclusive experiences and elevate your lifestyle to elite status. Join the elite circle today and redefine luxury living.</div>
                            </div>
                            {/* <div className="p-4 sm:w-1/2 lg:w-1/4 w-1/2">
                                <h2 className="title-font font-medium text-3xl text-gray-900">2.7K</h2>
                                <p className="leading-relaxed">Users</p>
                            </div>
                            <div className="p-4 sm:w-1/2 lg:w-1/4 w-1/2">
                                <h2 className="title-font font-medium text-3xl text-gray-900">1.8K</h2>
                                <p className="leading-relaxed">Subscribes</p>
                            </div>
                            <div className="p-4 sm:w-1/2 lg:w-1/4 w-1/2">
                                <h2 className="title-font font-medium text-3xl text-gray-900">35</h2>
                                <p className="leading-relaxed">Downloads</p>
                            </div>
                            <div className="p-4 sm:w-1/2 lg:w-1/4 w-1/2">
                                <h2 className="title-font font-medium text-3xl text-gray-900">4</h2>
                                <p className="leading-relaxed">Products</p>
                            </div> */}
                        </div>
                        {/* <div className="lg:w-1/4 sm:w-1/3 h-[430px] sm:h-[60vh] w-full rounded-lg overflow-hidden mt-6 sm:mt-0">
                            <img className=" relative object-cover object-top w-full h-full" src={img11} alt="stats" />
                        </div> */}
                        <div data-aos="fade-left" data-aos-easing="ease-out-cubic"
                            data-aos-duration="3000" className="hidden md:block shadow-xl lg:w-1/4 sm:w-1/3 h-[430px] sm:h-[60vh] w-full rounded-lg overflow-hidden mt-6 sm:mt-0">
                            <img className=" relative  object-cover object-cover w-full h-full" src={img11} alt="stats" />
                        </div>

                        <div data-aos="fade-up" data-aos-easing="ease-out-cubic"
                            data-aos-duration="3000" className="block md:hidden shadow-xl lg:w-1/4 sm:w-1/3 h-[430px] sm:h-[60vh] w-full rounded-lg overflow-hidden mt-6 sm:mt-0">
                            <img className=" relative  object-cover object-cover w-full h-full" src={img11} alt="stats" />
                        </div>
                    </div>
                </section>
                <section className="  text-gray-600 body-font">
                    <div className=" bg-yellow-600 container px-5 py-24 justify-around mx-auto flex flex-wrap">
                        <div className="  flex flex-wrap -mx-4 mt-auto mb-auto lg:w-1/2 sm:w-2/3 content-start sm:pr-10">
                            <div className="w-full sm:p-4 px-4 mb-6">
                                <div data-aos="flip-up" data-aos-easing="ease-out-cubic"
                                    data-aos-duration="3000">
                                    <h1 className="title-font font-medium text-2xl mb-2 text-gray-900">Anara Styles</h1>
                                </div>
                                <div data-aos="fade-in" data-aos-easing="ease-out-cubic"
                                    data-aos-duration="3000" className="leading-relaxed text-slate-200 ">Elevate your wardrobe with Anara Styles – Where fashion meets flair, and every piece tells a story of elegance. Explore our collection and redefine your style with timeless sophistication. Embrace the allure of Anara and make every outfit a statement.</div>
                            </div>
                            {/* <div className="p-4 sm:w-1/2 lg:w-1/4 w-1/2">
                                <h2 className="title-font font-medium text-3xl text-gray-900">2.7K</h2>
                                <p className="leading-relaxed">Users</p>
                            </div>
                            <div className="p-4 sm:w-1/2 lg:w-1/4 w-1/2">
                                <h2 className="title-font font-medium text-3xl text-gray-900">1.8K</h2>
                                <p className="leading-relaxed">Subscribes</p>
                            </div>
                            <div className="p-4 sm:w-1/2 lg:w-1/4 w-1/2">
                                <h2 className="title-font font-medium text-3xl text-gray-900">35</h2>
                                <p className="leading-relaxed">Downloads</p>
                            </div>
                            <div className="p-4 sm:w-1/2 lg:w-1/4 w-1/2">
                                <h2 className="title-font font-medium text-3xl text-gray-900">4</h2>
                                <p className="leading-relaxed">Products</p>
                            </div> */}
                        </div>
                        {/* <div className="lg:w-1/4 sm:w-1/3 h-[430px] sm:h-[60vh] w-full rounded-lg overflow-hidden mt-6 sm:mt-0">
                            <img className=" relative object-left object-cover  w-full h-full" src={img18} alt="stats" />
                        </div> */}
                        <div data-aos="fade-left" data-aos-easing="ease-out-cubic"
                            data-aos-duration="3000" className="hidden md:block shadow-xl lg:w-1/4 sm:w-1/3 h-[430px] sm:h-[60vh] w-full rounded-lg overflow-hidden mt-6 sm:mt-0">
                            <img className=" relative  object-cover object-cover w-full h-full" src={img18} alt="stats" />
                        </div>

                        <div data-aos="fade-up" data-aos-easing="ease-out-cubic"
                            data-aos-duration="3000" className="block md:hidden shadow-xl lg:w-1/4 sm:w-1/3 h-[430px] sm:h-[60vh] w-full rounded-lg overflow-hidden mt-6 sm:mt-0">
                            <img className=" relative  object-cover object-cover w-full h-full" src={img18} alt="stats" />
                        </div>
                    </div>
                </section>

                <div className='block md:hidden' >
                    <br /><br /><br />
                </div>
                <div className='hidden md:block' >
                    <br /><br /><br />
                </div>
                <div data-aos="flip-up" data-aos-easing="ease-out-cubic"
                    data-aos-duration="3000" className='' >  <div className=" text-yellow-600 flex justify-center  items-center text-2xl md:text-4xl " > <span className="    " ></span>Graphic Designing<span className="" ></span></div>
                </div>


                <section className="   body-font">
                    <div className="   container px-5 py-24 justify-around mx-auto flex flex-wrap">
                        <div className="  flex flex-wrap -mx-4 mt-auto mb-auto lg:w-1/2 sm:w-2/3 content-start sm:pr-10">
                            <div className="w-full sm:p-4 px-4 mb-6">
                                <div data-aos="flip-up" data-aos-easing="ease-out-cubic"
                                    data-aos-duration="3000">
                                    <h1 className="title-font font-medium text-2xl mb-2 text-yellow-600">ARE Digital Marketing</h1>
                                </div>
                                <div data-aos="fade-in" data-aos-easing="ease-out-cubic"
                                    data-aos-duration="3000" className="leading-relaxed">Transform your digital presence with our expert digital marketing solutions – Where strategy meets innovation, and results exceed expectations. Elevate your brand, engage your audience, and dominate the digital landscape with our tailored campaigns. Let's unlock your online potential together.</div>
                            </div>
                            {/* <div className="p-4 sm:w-1/2 lg:w-1/4 w-1/2">
                                <h2 className="title-font font-medium text-3xl text-gray-900">2.7K</h2>
                                <p className="leading-relaxed">Users</p>
                            </div>
                            <div className="p-4 sm:w-1/2 lg:w-1/4 w-1/2">
                                <h2 className="title-font font-medium text-3xl text-gray-900">1.8K</h2>
                                <p className="leading-relaxed">Subscribes</p>
                            </div>
                            <div className="p-4 sm:w-1/2 lg:w-1/4 w-1/2">
                                <h2 className="title-font font-medium text-3xl text-gray-900">35</h2>
                                <p className="leading-relaxed">Downloads</p>
                            </div>
                            <div className="p-4 sm:w-1/2 lg:w-1/4 w-1/2">
                                <h2 className="title-font font-medium text-3xl text-gray-900">4</h2>
                                <p className="leading-relaxed">Products</p>
                            </div> */}
                        </div>
                        {/* <div className="lg:w-1/4 sm:w-1/3 h-[430px] sm:h-[60vh] w-full rounded-lg overflow-hidden mt-6 sm:mt-0">
                            <img className=" relative object-cover object-top w-full h-full" src={img19} alt="stats" />
                        </div> */}
                        <div data-aos="fade-left" data-aos-easing="ease-out-cubic"
                            data-aos-duration="3000" className="hidden md:block shadow-xl lg:w-1/4 sm:w-1/3 h-[430px] sm:h-[60vh] w-full rounded-lg overflow-hidden mt-6 sm:mt-0">
                            <img className=" relative  object-cover object-cover w-full h-full" src={img19} alt="stats" />
                        </div>

                        <div data-aos="fade-up" data-aos-easing="ease-out-cubic"
                            data-aos-duration="3000" className="block md:hidden shadow-xl lg:w-1/4 sm:w-1/3 h-[430px] sm:h-[60vh] w-full rounded-lg overflow-hidden mt-6 sm:mt-0">
                            <img className=" relative  object-cover object-cover w-full h-full" src={img19} alt="stats" />
                        </div>
                    </div>
                </section>

                <section className="   body-font">
                    <div className=" bg-yellow-600 container px-5 py-24 justify-around mx-auto flex flex-wrap">
                        <div className="  flex flex-wrap -mx-4 mt-auto mb-auto lg:w-1/2 sm:w-2/3 content-start sm:pr-10">
                            <div className="w-full sm:p-4 px-4 mb-6">
                                <div data-aos="flip-up" data-aos-easing="ease-out-cubic"
                                    data-aos-duration="3000">
                                    <h1 className="title-font font-medium text-2xl mb-2 text-black">ARB Collections </h1>
                                </div>
                                <div data-aos="fade-in" data-aos-easing="ease-out-cubic"
                                    data-aos-duration="3000" className="leading-relaxed text-slate-200 ">Experience the artistry of ARB Graphic Design – Where creativity knows no bounds and every design is a masterpiece. From captivating visuals to innovative concepts, we bring your ideas to life with precision and flair. Explore the world of ARB Graphic Design and elevate your brand with unparalleled creativity.</div>
                            </div>
                            {/* <div className="p-4 sm:w-1/2 lg:w-1/4 w-1/2">
                                <h2 className="title-font font-medium text-3xl text-gray-900">2.7K</h2>
                                <p className="leading-relaxed">Users</p>
                            </div>
                            <div className="p-4 sm:w-1/2 lg:w-1/4 w-1/2">
                                <h2 className="title-font font-medium text-3xl text-gray-900">1.8K</h2>
                                <p className="leading-relaxed">Subscribes</p>
                            </div>
                            <div className="p-4 sm:w-1/2 lg:w-1/4 w-1/2">
                                <h2 className="title-font font-medium text-3xl text-gray-900">35</h2>
                                <p className="leading-relaxed">Downloads</p>
                            </div>
                            <div className="p-4 sm:w-1/2 lg:w-1/4 w-1/2">
                                <h2 className="title-font font-medium text-3xl text-gray-900">4</h2>
                                <p className="leading-relaxed">Products</p>
                            </div> */}
                        </div>
                        {/* <div className="lg:w-1/4 sm:w-1/3 h-[430px] sm:h-[60vh] w-full rounded-lg overflow-hidden mt-6 sm:mt-0">
                            <img className=" relative object-top object-cover  w-full h-full" src={team89} alt="stats" />
                        </div> */}
                        <div data-aos="fade-left" data-aos-easing="ease-out-cubic"
                            data-aos-duration="3000" className="hidden md:block shadow-xl lg:w-1/4 sm:w-1/3 h-[430px] sm:h-[60vh] w-full rounded-lg overflow-hidden mt-6 sm:mt-0">
                            <img className=" relative  object-cover object-cover w-full h-full" src={team89} alt="stats" />
                        </div>

                        <div data-aos="fade-up" data-aos-easing="ease-out-cubic"
                            data-aos-duration="3000" className="block md:hidden shadow-xl lg:w-1/4 sm:w-1/3 h-[430px] sm:h-[60vh] w-full rounded-lg overflow-hidden mt-6 sm:mt-0">
                            <img className=" relative  object-cover object-cover w-full h-full" src={team89} alt="stats" />
                        </div>
                    </div>
                </section>



            </div>
            <div className='block md:hidden' >
                <br /><br /><br /><br /><br />
            </div>
            <div className='hidden md:block' >
                <br /><br /><br />
            </div>
            <div className='  flex justify-center  pr-5 pl-5 md:pl-0 md:pr-0 items-center md:h-[100vh] ' >
                <div >
                    <div className='block md:hidden' >
                        <img data-aos="fade-up" data-aos-easing="ease-out-cubic"
                            data-aos-duration="3000" src={img8} alt="" />
                        {/* <img src={img3} alt="" /> */}
                    </div>
                    <div className='hidden md:block '>
                        <div className='flex' >
                            <img data-aos="fade-right" data-aos-easing="ease-out-cubic"
                                data-aos-duration="3000" src={img7} alt="" />
                            <img data-aos="fade-up" data-aos-easing="ease-out-cubic"
                                data-aos-duration="3000" src={img5} alt="" />

                        </div>
                    </div>
                </div>
            </div >

            {/* parallex starts */}

            <div className='block md:hidden' >
                <br /><br /><br /><br /><br />
            </div>
            <div className='hidden md:block' >
                <br /><br /><br />
            </div>

            {/* parallex ends */}


            {/* Footer starts */}
            <div className='h-auto w-auto'>


                <Footer />




            </div>
            {/* Footer ends */}
        </div>

    )
}