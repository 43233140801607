// import React from 'react';
// import ReactPlayer from 'react-player';

// export default function SmallSlider() {
//     return (
//         <div>
//             <ReactPlayer
//                 className="react-player"
//                 url="https://www.sample-videos.com/video123/mp4/480/big_buck_bunny_480p_1mb.mp4"
//                 width="100%"
//                 height="100%"
//                 controls
//                 muted
//             />
//         </div>
//     );
// }
// ImageSlider.js
import React, { useState, useEffect } from 'react';
// import ReactPlayer from 'react-player';

import img1 from "../images/icon3.gif"
import img2 from "../images/icon4.gif"
import img3 from "../images/icon5.gif"
import img4 from "../images/icon6.gif"
import img5 from "../images/icon7.gif"
import img6 from "../images/icon9.gif"
import img7 from "../images/icon8.gif"
import img8 from "../images/icon10.gif"
import img9 from "../images/icon11.gif"
import img10 from "../images/icon12.gif"
// import img11 from "../images/muzammil.jpeg"
// import img11 from "../images/abdullah.jpeg"


import AOS from 'aos';
import 'aos/dist/aos.css';


const ImageSlider = () => {
    useEffect(() => {
        AOS.init({ duration: 800 });


    }, []);

    const [currentIndex, setCurrentIndex] = useState(0);
    const totalSlides = 10;

    const nextSlide = () => {
        if (currentIndex < totalSlides - 1) {
            setCurrentIndex(currentIndex + 1);
        }
    };

    const prevSlide = () => {
        if (currentIndex > 0) {
            setCurrentIndex(currentIndex - 1);
        }
    };

    useEffect(() => {
        const glowBar = document.getElementById('glow-bar');
        glowBar.style.width = `${5 + currentIndex * (95 / (totalSlides - 1))}%`;
    }, [currentIndex, totalSlides]);

    const sliderStyles = {
        container: {
            position: 'relative',
            margin: 'auto',
            marginTop: '20px',
            overflow: 'hidden',
            boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
            borderRadius: '8px',
        },
        slider: {
            display: 'flex',
            transition: 'transform 1s ease-in-out',
            flexDirection: 'row',
            height: '100%', // Added height for vertical layout
        },
        slide: {
            flex: '0 0 100%',
            boxSizing: 'border-box',
            textAlign: 'center',
            padding: '30px',
            border: '1px solid #ddd',
            borderRadius: '8px',
            background: 'rgb(202, 138, 4)',
            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
            display: 'flex',
            flexDirection: 'column', // Changed to column for text above image
            alignItems: 'center',
            justifyContent: 'center',
        },
        image: {
            maxWidth: '100%',
            maxHeight: '200px',
            borderRadius: '4px',
            marginTop: '20px', // Adjusted margin for spacing
        },
        textContainer: {
            textAlign: 'center',
        },
        animalName: {
            color: 'black',
            fontSize: '24px',
            marginBottom: '15px',
        },
        animalDetails: {
            color: 'white',
            fontSize: '16px',
        },
        glowBar: {
            height: '8px',
            backgroundColor: 'black',
            marginTop: '12px',
            borderRadius: '4px',
            transition: 'width 1s ease-in-out',
            width: `${5 + currentIndex * (95 / (totalSlides - 1))}%`,
        },
        button: {
            position: 'absolute',
            top: '56%',
            transform: 'translateY(-50%)',
            padding: '10px',
            fontSize: '24px',
            color: '#fff',
            borderRadius: '50%',
            cursor: 'pointer',
            border: 'none',
            outline: 'none',
            backgroundColor: 'black',
        },
        prevButton: {
            left: '20px',
        },
        nextButton: {
            right: '20px',
        },
    };


    const heading = ["INITIATION", "REQUIREMENT ANALYSIS", "SYSTEM DESIGN", "PROTOTYPE DEVELOPEMENT", "IMPLEMENTATION (CODING)", "UNIT TESTING", "INTEGERATION TESTING", "SYSTEM TESTING", "USER ACCEPTANCE TESTING (UAT)", "DEPLOYMENT & MAINTENANCE",]

    const detail = ["Define the scope and objectives of the project. Identify stakeholders and their roles. Conduct a feasibility study to assess technical, economic, and operational aspects.",
        "Gather and document detailed requirements through interviews, workshops, and documentation review.Prioritize requirements based on business needs and technical constraints.Create requirement traceability matrices to track requirements throughout the project.",
        "Design the overall architecture of the software system, including data flow, components, and interfaces.Create detailed design documents such as architectural diagrams, database schemas, and interface designs.Define non-functional requirements like performance, scalability, and security.",
        "Build prototypes or mockups to validate design decisions and gather feedback from stakeholders.Iteratively refine the prototypes based on user feedback and requirements.",
        "Write code according to the design specifications using appropriate programming languages and frameworks.Follow coding standards and best practices to ensure code quality and maintainability.",
        "Develop unit tests to verify the functionality of individual components or modules.Execute unit tests using automated testing frameworks to identify and fix defects early in the development process.Ensure code coverage to assess the effectiveness of unit testing.",
        "Integrate individual components or modules to form the complete system.Conduct integration tests to verify the interactions and interfaces between different components.Address any integration issues and ensure that the system functions as expected as a whole.",
        "Perform comprehensive testing of the entire system to validate its compliance with requirements.Test various scenarios and edge cases to uncover defects and assess system performance.Conduct regression testing to ensure that new changes do not introduce unintended side effects.",
        "Involve end-users or representatives to test the software in a real-world environment.Validate that the software meets the business needs and is user-friendly.Address feedback from users and make necessary adjustments before finalizing the product.",
        "Deploy the software to the production environment, ensuring smooth installation and configuration.Provide training and support to end-users to facilitate adoption of the new software.Monitor and maintain the software post-deployment, addressing any issues or enhancements through regular updates and patches.",
    ]

    const pics = [img1, img2, img3, img4, img5, img6, img7, img8, img9, img10]
    // const pics = [img1, img2.img3, img4]


    const placeholderData = Array.from({ length: totalSlides }, (_, index) => ({
        name: `${heading[index]} `,
        details: `${detail[index]}`,
        images: `${pics[index]}`,
        // imageUrl: `https://via.placeholder.com/800x400?text=Animal${index + 1}`,
    }));


    return (
        <div style={sliderStyles.container}>
            <div style={{ ...sliderStyles.slider, ...{ transform: `translateX(-${currentIndex * 100}%)` } }} id="slider">
                {placeholderData.map((animal, index) => (
                    <div key={index} style={sliderStyles.slide}>
                        <div style={sliderStyles.textContainer}>
                            <p data-aos="flip-up" data-aos-easing="ease-out-cubic"
                                data-aos-duration="1500" style={sliderStyles.animalName}>{animal.name}</p>
                            <p data-aos="fade-in" data-aos-easing="ease-out-cubic"
                                data-aos-duration="3000" style={sliderStyles.animalDetails}>{animal.details}</p>
                        </div>
                        <br />
                        {/* <img src={animal.images} alt={animal.images} style={sliderStyles.image} /> */}
                        {/* <div className=" relative outline-none group"> */}
                        {/* <video className=" w-52 h-52 md:w-32 md:h-32 -mb-7 md:m-0 " loop autoPlay muted  >
                            <source src={animal.images} type="video/mp4" />
                        </video> */}

                        <img data-aos="zoom-in" data-aos-easing="ease-out-cubic"
                            data-aos-duration="1500" src={animal.images} alt="" srcSet="" className='w-52 h-52 md:w-32 md:h-32 -mb-7 md:m-0' />

                        {/* <ReactPlayer
                            className=" -mb-14 md:m-0"
                            url={animal.images} // Ensure `animal.images` contains the correct video URL
                            width="200px"       // Specify width in pixels for better control
                            height="200px"      // Specify height in pixels for better control
                            // controls            // Enable playback controls
                            autoPlay
                            loop
                            muted
                            onError={(e) => console.log('Error while playing video:', e)}
                        /> */}

                        {/* <div className="absolute inset-0 opacity-0 group-hover:opacity-100 transition-opacity"> */}
                        {/* Play/Pause button or other controls can go here */}
                        {/* </div> */}
                        {/* <img src={img11} width="100px" height="100px" alt="" srcSet="" /> */}
                        {/* </div> */}
                    </div>
                ))}
            </div>
            <div style={sliderStyles.glowBar} id="glow-bar"></div>
            <button
                data-aos="fade-right" data-aos-easing="ease-out-cubic"
                data-aos-duration="1500"
                style={{ ...sliderStyles.button, ...sliderStyles.prevButton }}
                onClick={prevSlide}
            >
                &lt;
            </button>
            <button
                data-aos="fade-left" data-aos-easing="ease-out-cubic"
                data-aos-duration="1500"
                style={{ ...sliderStyles.button, ...sliderStyles.nextButton }}
                onClick={nextSlide}
            >
                &gt;
            </button>
        </div >
    );
};

export default ImageSlider;
