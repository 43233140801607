import React, { useEffect, useRef, useState } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import img1 from "../images/team1.jpeg";
import img2 from "../images/team2.jpeg";
import img3 from "../images/image123.jpg";
import img4 from "../images/image123.jpg";
import img5 from "../images/image123.jpg";
import img6 from "../images/image123.jpg";

export default function SmallTeam() {
    useEffect(() => {
        AOS.init({ duration: 800 });
    }, []);

    const [centerIndex, setCenterIndex] = useState(null);
    const imageRefs = useRef([]);

    useEffect(() => {
        const handleScroll = () => {
            const centerIndex = calculateCenterImage();
            setCenterIndex(centerIndex);
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const calculateCenterImage = () => {
        const windowHeight = window.innerHeight;
        const scrollTop = window.scrollY;
        const scrollCenter = scrollTop + (windowHeight / 2.5);

        let centerIndex = null;

        imageRefs.current.forEach((ref, index) => {
            const rect = ref.getBoundingClientRect();
            const elementTop = rect.top + scrollTop;
            const elementBottom = rect.bottom + scrollTop;

            if (elementTop <= scrollCenter && elementBottom >= scrollCenter) {
                centerIndex = index;
            }
        });

        return centerIndex;
    };

    return (
        <div>
            <div data-aos="zoom-in" data-aos-easing="ease-out-cubic" data-aos-duration="3000" className='pt-16'>
                <div className="flex justify-center items-center text-5xl md:text-6xl">
                    <span className=""><hr className="w-14 md:w-20 border-2 border-yellow-600" /></span> Team <span className=""><hr className="w-14 md:w-20 border-2 border-yellow-600" /></span>
                </div>
            </div>
            <br /><br />
            <p data-aos="fade-in" data-aos-easing="ease-out-cubic"
                data-aos-duration="3000" className="lg:w-2/3 px-5 text-center mx-auto leading-relaxed text-base  ">
                Meet the driving force behind our dedicated and talented team, HashStackDevelopers. With our diverse skills and relentless passion, we work together to make the vision a reality. Together, we are committed to delivering outstanding results that take your digital journey to new heights.
            </p>
            <br /><br />
            <div className="flex items-center justify-center">
                {/* Outer Big Circle */}
                <div data-aos="zoom-in" data-aos-easing="ease-out-cubic" data-aos-duration="5000" className="w-[340px] h-[340px] rounded-full border-2 border-yellow-300 relative flex items-center justify-center">
                    {/* Inner Small Circle at Top-Left */}
                    <div className="w-14 h-14 bg-yellow-600 rounded-full absolute top-[40px] left-[0px]">
                        <img src={img6} alt="" className="rounded-full h-14 w-14 contrast-100 object-cover border-2 border-yellow-300" ref={el => (imageRefs.current[0] = el)} />
                        {centerIndex === 0 && <div className='bg-yellow-600  p-[6px] w-[120px] ml-1  border-2 shadow-lg border-black z-10 absolute top-[10%] left-[100%] border-l-4  rounded-r-2xl' >
                            <p className='text-[10px] font-semibold tracking-wider ' >Muzammil Mughal</p>
                            <p className='text-[8px] text-white  font-semibold tracking-wider' >IT Manager</p>
                        </div>}
                    </div>
                    <div className="w-14 h-14 bg-yellow-600 rounded-full absolute bottom-[40px] right-[0px]">
                        <img src={img5} alt="" className="rounded-full h-14 w-14 contrast-100 object-cover border-2 border-yellow-300" ref={el => (imageRefs.current[2] = el)} />
                        {centerIndex === 2 && <div className='bg-yellow-600 p-[6px] w-24 mt-1  border-2 shadow-lg border-black z-10 absolute top-[100%] right-0 border-t-4  rounded-b-2xl' >
                            <p className='text-[10px] text-left font-semibold tracking-wider ' >Arooj Raja</p>
                            <p className='text-[8px] text-left text-white font-semibold tracking-wider' >Project Manager</p>
                        </div>}
                    </div>
                    <div className="w-14 h-14 bg-yellow-600 rounded-full absolute bottom-[40px] left-[0px]">
                        <img src={img4} alt="" className="rounded-full h-14 w-14 contrast-100 object-cover border-2 border-yellow-300" ref={el => (imageRefs.current[2] = el)} />
                        {centerIndex === 2 && <div className='bg-yellow-600 p-[6px] w-[90px] mt-1 border-2 shadow-lg border-black z-10 absolute top-[100%] left-0 border-t-4  rounded-b-2xl' >
                            <p className='text-[10px] text-left font-semibold tracking-wider ' >Faizan Imran</p>
                            <p className='text-[8px] text-left text-white  font-semibold tracking-wider' >HR Manager</p>
                        </div>}
                    </div>
                    {/* Another Inner Big Circle in the Middle */}
                    <div data-aos="zoom-in" data-aos-easing="ease-out-cubic" data-aos-duration="4000" className="w-[250px] h-[250px] rounded-full border-2 border-yellow-400 absolute flex items-center justify-center">
                        <div className="w-14 h-14 bg-red-500 rounded-full absolute top-5 -right-1">
                            <img src={img3} alt="" className="rounded-full h-14 w-14 contrast-100 object-cover border-2 border-yellow-400" ref={el => (imageRefs.current[3] = el)} />
                            {centerIndex === 3 &&

                                <div className='bg-yellow-600 p-[6px] w-[120px] mr-1  border-2 shadow-lg border-black z-10 absolute top-[10%] right-[100%] border-r-4  rounded-l-2xl' >
                                    <p className='text-[10px] text-right font-semibold tracking-wider ' >Talha Rizwan</p>
                                    <p className='text-[8px] text-right text-white  font-semibold tracking-wider' >Chief Marketing Officer</p>
                                </div>

                            }
                        </div>
                        {/* Yet Another Inner Big Circle in the Middle */}
                        <div data-aos="zoom-in" data-aos-easing="ease-out-cubic" data-aos-duration="3000" className="w-[160px] h-[160px] rounded-full border-2 border-yellow-500 absolute flex items-center justify-center relative">
                            <div className="w-14 h-14 bg-pink-500 rounded-full absolute -bottom-[30px] left-30">
                                <img src={img2} alt="" className="rounded-full h-14 w-14 contrast-100 object-cover border-2 border-yellow-500" ref={el => (imageRefs.current[4] = el)} />
                                {centerIndex === 4 && <div className='bg-yellow-600 p-[6px] w-28 mt-1  border-2 shadow-lg border-black z-10 relative border-t-4 absolute right-5  rounded-b-2xl' >
                                    <p className='text-[10px] font-semibold tracking-wider' >Syed Ahsan Naqvi</p>
                                    <p className='text-[8px] text-white font-semibold tracking-wider' >Engr. Manager</p>
                                </div>}
                            </div>
                            {/* One More Inner Big Circle in the Middle */}
                            <div data-aos="zoom-in" data-aos-easing="ease-out-cubic" data-aos-duration="2000" className="w-[80px] h-[80px] rounded-full border-2 border-yellow-600 absolute flex items-center justify-center relative">
                                <div className="w-14 h-14 rounded-full absolute bottom-30 -left-5">
                                    <img src={img1} alt="" className="rounded-full h-14 w-14 contrast-100 object-cover border-2 border-yellow-600" ref={el => (imageRefs.current[5] = el)} />
                                    {<div className='bg-yellow-600 p-[6px] w-28 ml-1  border-2 shadow-lg border-black z-10 absolute top-[10%] left-[100%] border-l-4  rounded-r-2xl' >
                                        <p className='text-[10px] font-semibold tracking-wider ' >M. Mateen Imran</p>
                                        <p className='text-[8px] text-white font-semibold tracking-wider' >Founder CEO</p>
                                    </div>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <br /><br /><br />
        </div>
    );
}
